import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AppRootContextProvider } from "../contexts/AppRootContext";

import AdminLayout from "./AdminLayout";
import LoggedInRoute from "../routes/ProtectedRoute/LoggedInRoute";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";

function CommonLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppRootContextProvider>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/login" component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        {/* <Route exact path="/reset-password" component={AdminPassReset} /> */}
        <LoggedInRoute component={AdminLayout} />
      </Switch>
    </AppRootContextProvider>
  );
}

export default CommonLayout;
