import React, { useEffect, useState, useRef } from 'react';
import { Form, Button, Row, Col, Select, Table, DatePicker, Spin } from 'antd';
import { AdminAPI } from '../../utils/Api';
import Notification from '../../components/controls/Notification';
import moment from 'moment';
var FileSaver = require('file-saver');

const OneViewReport = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (value) => {
    const from = moment(value.date_range).format('YYYY-MM-DD');

    try {
      const res = await AdminAPI.post(
        '/report/one-view-report',
        { from },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      );
      const blob = new Blob([base64ToArrayBuffer(res.data.data)]);
      FileSaver.saveAs(blob, `One_View_Report_VUSE_${moment().format('L')}.xlsx`);
    } catch (error) {
      Notification('Could not download one view report.', 'One view report could not be downloaded. Please try again later.', 'error');
    }
  };

  const base64ToArrayBuffer = (base64) => {
    console.log('One view Report base64', base64);
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  return (
    <Spin spinning={loading}>
      <div className='reportmanager-div'>
        <Form name='report-manager' initialValues={{ remember: true }} layout='vertical' onFinish={onFinish} form={form}>
          <h1 className='title'>One View Report VUSE</h1>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Col xs={24} lg={12}>
              <Form.Item label='Select Month' name='date_range' rules={[{ required: true, message: 'Please select a month!' }]}>
                <DatePicker style={{ width: '100%' }} picker='month' />
              </Form.Item>
            </Col>
            <Col xs={20} lg={10}>
              <Form.Item style={{ margin: 0 }}>
                <Button shape='round' type='primary' htmlType='submit'>
                  Generate Report
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default OneViewReport;
