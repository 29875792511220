import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, Row, Col, Spin } from 'antd';
import { AdminAPI } from '../../utils/Api';
import ErrorHandler from '../../utils/ErrorHandler';
import Notification from '../../components/controls/Notification';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const LocationFields = ({ form, locations, setLocations, edit, branchLoc }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [locations, setLocations] = useState([]);
  const [region, setRegion] = useState([]);
  const [area, setArea] = useState([]);
  const [territory, setTerritory] = useState([]);
  const [d_house, setDhouse] = useState([]);
  const [dpid, setDPID] = useState([]);
  const [vape_loc, setVapeLoc] = useState([]);

  useEffect(() => {
    if (!edit) {
      fetchLocations();
    } else {
      fetchLocations();
      if (branchLoc.length > 0) {
        let regionArr = [];
        let areaArr = [];
        let territoryArr = [];
        let distributionArr = [];
        let pointArr = [];

        branchLoc.forEach((element) => {
          if (regionArr.includes(element.reg_id)) {
            return;
          } else {
            regionArr.push(element.reg_id);
          }
        });
        let area = onChangeHandler(regionArr);
        form.setFieldsValue({
          region: regionArr,
          vape_location: branchLoc[0].loc_id,
        });
      }
    }
  }, [edit == true ? branchLoc : null]);

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const { data } = await AdminAPI.get('/shop/location-info', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setLocations(data.data);

      let regionArr = data?.data.map((v) => {
        return {
          id: v.reg_id,
          name: v.region,
        };
      });
      const key = 'id';
      const arrayUniqueByKey = [...new Map(regionArr.map((item) => [item[key], item])).values()];
      setRegion(arrayUniqueByKey);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const onChangeHandler = (value) => {
    form.setFieldsValue({
      vape_location: null,
    });
    if (value) {
      let areaArr = [];
      let obj = {};
      if (locations.length > 0) {
        locations.forEach((element) => {
          if (value.includes(element.reg_id)) {
            let temObj = { id: element.area_id, name: element.area };
            if (areaArr.some((obj) => obj.id === element.area_id)) {
              return;
            } else {
              areaArr.push(temObj);
            }
          }
        });
        setArea([...areaArr]);
        let area = areaArr.map((v) => v.id);
        let territory = onAreaChangeHandler(area).map((v) => v.id);
        let distribution = onTerritoryChangeHandler(territory).map((v) => v.id);
        let loc = onDPChangeHandler(distribution).map((v) => v.id);

        obj['area'] = area;
        obj['territory'] = territory;
        obj['dp'] = distribution;
        // obj["point"] = point;
        form.setFieldsValue(obj);
      }
    }
  };

  const onAreaChangeHandler = (value) => {
    form.setFieldsValue({
      vape_location: null,
    });
    if (value) {
      let terrArr = [];
      let obj = {};
      if (locations.length > 0) {
        locations.forEach((element) => {
          if (value.includes(element.area_id)) {
            let temObj = { id: element.territory_id, name: element.territory };
            if (terrArr.some((obj) => obj.id === element.territory_id)) {
              return;
            } else {
              terrArr.push(temObj);
            }
          }
        });
        setTerritory([...terrArr]);
        let terr = terrArr.map((v) => v.id);
        let distribution = onTerritoryChangeHandler(terr).map((v) => v.id);
        let loc = onDPChangeHandler(distribution).map((v) => v.id);

        obj['territory'] = terr;
        obj['dp'] = distribution;
        // obj["point"] = point;
        form.setFieldsValue(obj);
      }

      return terrArr;
    }
  };

  const onTerritoryChangeHandler = (value) => {
    form.setFieldsValue({
      vape_location: null,
    });
    if (value) {
      let dpArr = [];
      let obj = {};
      if (locations.length > 0) {
        locations.forEach((element) => {
          if (value.includes(element.territory_id)) {
            let temObj = { id: element.dpid, name: element.dp };
            if (dpArr.some((obj) => obj.id === element.dpid)) {
              return;
            } else {
              dpArr.push(temObj);
            }
          }
        });
        setDPID([...dpArr]);
        let dp = dpArr.map((v) => v.id);
        let loc = onDPChangeHandler(dp).map((v) => v.id);

        obj['dp'] = dp;
        // obj["point"] = point;
        form.setFieldsValue(obj);
      }

      return dpArr;
    }
  };

  const onDPChangeHandler = (value) => {
    form.setFieldsValue({
      vape_location: null,
    });
    if (value) {
      let locArr = [];
      if (locations.length > 0) {
        locations.forEach((element) => {
          if (value.includes(element.dpid)) {
            let temObj = { id: element.loc_id, name: element.location };
            if (locArr.some((obj) => obj.id === element.loc_id)) {
              return;
            } else {
              locArr.push(temObj);
            }
          }
        });
        setVapeLoc([...locArr]);
      }

      return locArr;
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[32]}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item label='Region' name='region' rules={[{ required: true }]}>
            <Select mode='multiple' maxTagCount={4} allowClear showSearch optionFilterProp='children' showArrow onChange={onChangeHandler} style={{ width: '100%' }} placeholder='Type or select...'>
              {region.map((v, i) => (
                <Option value={v.id} key={i}>
                  {' '}
                  {v.name}{' '}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item label='Area' name='area' rules={[{ required: true }]}>
            <Select
              mode='multiple'
              maxTagCount={4}
              allowClear
              showSearch
              optionFilterProp='children'
              showArrow
              onChange={onAreaChangeHandler}
              style={{ width: '100%' }}
              placeholder='Type or select...'>
              {area.map((v, i) => (
                <Option value={v.id} key={i}>
                  {' '}
                  {v.name}{' '}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item label='Territory' name='territory' rules={[{ required: true }]}>
            <Select
              mode='multiple'
              maxTagCount={4}
              allowClear
              showSearch
              optionFilterProp='children'
              showArrow
              onChange={onTerritoryChangeHandler}
              style={{ width: '100%' }}
              placeholder='Type or select...'>
              {territory.map((v, i) => (
                <Option value={v.id} key={i}>
                  {' '}
                  {v.name}{' '}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item label='Distribution Point' name='dp' rules={[{ required: true }]}>
            <Select mode='multiple' maxTagCount={4} allowClear showSearch optionFilterProp='children' showArrow onChange={onDPChangeHandler} style={{ width: '100%' }} placeholder='Type or select...'>
              {dpid.map((v, i) => (
                <Option value={v.id} key={i}>
                  {' '}
                  {v.name}{' '}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          {/* <h5 className="required" style={{ fontWeight: 'bold', color: '#8B8B8B', fontSize: '12px' }}> Distribution House </h5> */}
          <Form.Item label='Locations' name='vape_location' rules={[{ required: true }]}>
            <Select
              mode='single'
              maxTagCount={4}
              allowClear
              showSearch
              optionFilterProp='children'
              showArrow
              // onChange={onDhouseChangeHandler}
              style={{ width: '100%' }}
              placeholder='Type or select...'>
              {vape_loc.map((v, i) => (
                <Option value={v.id} key={i}>
                  {' '}
                  {v.name}{' '}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  );
};

export default LocationFields;
