import { Redirect, Route, useLocation } from "react-router-dom";
import Notification from "../../components/controls/Notification";
import { Accesses } from "../../utils/Accesses";

const AccessProtected = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();
  const resourceList =
    (localStorage && localStorage.getItem("resource_list")) || [];
  const arrayString = resourceList?.split(",");

  let hasAccess;
  let splittedPath = pathname.split("/");

  // enter if condition if the last element of the array is not a number
  if (isNaN(+splittedPath.at(-1))) {
    // select requested url access code from accesses list
    const selectedURLAccess = Accesses.find(
      (el) => el.url === pathname
    ).access_code.toString();
    if (arrayString.includes(selectedURLAccess)) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  } else {
    // remove last element which is number and make the main url
    splittedPath.pop();
    splittedPath = splittedPath.join("/");

    const selectedURLAccess = Accesses.find(
      (el) => el.url === splittedPath
    )?.access_code?.toString();
    if (arrayString.includes(selectedURLAccess)) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasAccess) {
          return <Component {...props} />;
        } else {
          Notification(
            "Not Authorized!!",
            "Sorry! You do have permission to access the requested page!",
            "error"
          );
          return <Redirect to="/manager" />;
        }
      }}
    />
  );
};

export default AccessProtected;
