import React from "react";
import { Switch, Route } from "react-router-dom";
import DashboardBuilder from "../pages/DashboardManager/CreateDashboard";
import DashboardCard from "../pages/DashboardManager/DashboardCard";
import DashboardList from "../pages/DashboardManager/DashboardList";
import Dashboards from "../pages/DashboardManager/Dashboards";
import UpdateDashboard from "../pages/DashboardManager/UpdateDashboard";
import Home from "../pages/Home";
import ByChannelOrderReport from "../pages/ReportManager/ByChannelOrderReport";
import TradeSalesReport from "../pages/ReportManager/TradeSalesReport";
import ResetPassword from "../pages/ResetPassword";
import CreateBranch from "../pages/ShopManager/CreateBranch";
import EditBranch from "../pages/ShopManager/EditBranch";
import EditShop from "../pages/ShopManager/EditShop/EditShop";
import ShopCreation from "../pages/ShopManager/ShopCreation/ShopCreation";
import ViewBranch from "../pages/ShopManager/Viewbranch";
import ViewShop from "../pages/ShopManager/ViewShop/ViewShop";
import CreateUser from "../pages/UserManager/CreateUser";
import EditUser from "../pages/UserManager/EditUser";
import ViewUser from "../pages/UserManager/ViewUser";
import ByTradeReport from "../pages/ReportManager/ByTraderReport";
import OnHandReport from "../pages/ReportManager/OnHandReport";
import OneViewReport from "../pages/ReportManager/OneViewReport";
import Profile from "../pages/Profile/Profile";
import CreateRole from "../pages/RoleManager/CreateRole";
import AddResource from "../pages/RoleManager/AddResource";
import RoleList from "../pages/RoleManager/RoleList";
import ResourceList from "../pages/RoleManager/ResourceList";
import EditResource from "../pages/RoleManager/EditResource";
import EditRole from "../pages/RoleManager/EditRole";
import ByConsumerSales from "../pages/ReportManager/ByConsumerSales";
import RegisteredUsers from "../pages/ReportManager/RegisteredUsers";
import AllocateStock from "../pages/StockManager/AllocateStock";
import StockLists from "../pages/StockManager/StockLists";
import EditAllocatedStock from "../pages/StockManager/EditAllocatedStock";
import AccessProtected from "./AccessProtected";
import NormalBranchStock from "../pages/StockManager/NormalBranchStock";
import BranchDetails from "../pages/ShopManager/BranchDetails";
import SOBReport from "../pages/ReportManager/SOBReport";
import OrderPanel from "../pages/OrderPanel";

function AppRoutes() {
  return (
    <div>
      <Switch>
        <Route exact path="/manager" component={Home} />
        <AccessProtected path="/stock-status/toggle" component={OrderPanel} />

        <AccessProtected path="/user-manager/view-user" component={ViewUser} />
        <AccessProtected
          path="/user-manager/create-user"
          component={CreateUser}
        />
        <AccessProtected
          path="/user-manager/edit-user/:id"
          component={EditUser}
        />
        <Route path="/me" component={Profile} />

        <AccessProtected
          path="/shop-manager/create-shop"
          component={ShopCreation}
        />
        <AccessProtected path="/shop-manager/view-shop" component={ViewShop} />
        <AccessProtected
          path="/shop-manager/edit-shop/:id"
          component={EditShop}
        />
        <AccessProtected
          path="/shop-manager/create-branch"
          component={CreateBranch}
        />
        <AccessProtected
          path="/shop-manager/view-branch"
          component={ViewBranch}
        />
        <AccessProtected
          path="/shop-manager/edit-branch/:id"
          component={EditBranch}
        />
        <AccessProtected
          path="/shop-manager/branch-details/:id"
          component={BranchDetails}
        />

        <AccessProtected
          path="/report-manager/by-channel-order"
          component={ByChannelOrderReport}
        />
        <AccessProtected
          path="/report-manager/by-trader-sales"
          component={TradeSalesReport}
        />
        <AccessProtected
          path="/report-manager/by-trade-lifting-report"
          component={ByTradeReport}
        />
        <AccessProtected
          path="/report-manager/on-hand-stock-report"
          component={OnHandReport}
        />
        <AccessProtected
          path="/report-manager/one-view-report"
          component={OneViewReport}
        />
        <AccessProtected
          path="/report-manager/by-consumer-sales"
          component={ByConsumerSales}
        />
        <AccessProtected
          path="/report-manager/registered-users"
          component={RegisteredUsers}
        />
        <AccessProtected
          path="/report-manager/sob-report"
          component={SOBReport}
        />

        <AccessProtected
          path="/dashboard-manager/dashboards"
          component={Dashboards}
        />
        <AccessProtected
          path="/dashboard-manager/create-dashboard"
          component={DashboardBuilder}
        />
        <AccessProtected
          path="/dashboard-manager/dashboard-card/:id"
          component={DashboardCard}
        />
        <AccessProtected
          path="/dashboard-manager/dashboard-list"
          component={DashboardList}
        />
        <AccessProtected
          path="/dashboard-manager/update-dashboard/:id"
          component={UpdateDashboard}
        />

        <AccessProtected
          path="/role-manager/create-role"
          component={CreateRole}
        />
        <AccessProtected
          path="/role-manager/add-resource"
          component={AddResource}
        />
        <AccessProtected path="/role-manager/role-list" component={RoleList} />
        <AccessProtected
          path="/role-manager/resource-list"
          component={ResourceList}
        />
        <AccessProtected
          path="/role-manager/edit-resource/:id"
          component={EditResource}
        />
        <AccessProtected
          path="/role-manager/edit-role/:id"
          component={EditRole}
        />

        <AccessProtected
          path="/stock-manager/allocate-stock"
          component={AllocateStock}
        />
        <AccessProtected
          path="/stock-manager/stock-lists"
          component={StockLists}
        />
        <AccessProtected
          path="/stock-manager/normal-branch-stock"
          component={NormalBranchStock}
        />
      </Switch>
    </div>
  );
}

export default AppRoutes;
