import { useEffect, useState } from 'react';
import { Card, Col, Descriptions, Row, Spin, Switch, Table, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import Notification from '../../../components/controls/Notification';
import { AdminAPI } from '../../../utils/Api';
import ErrorHandler from '../../../utils/ErrorHandler';
import { hasAccess } from '../../../utils/Helper';

const { Title } = Typography;

const ShopDetails = () => {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [branchDetailsInformation, setBranchDetailsInformation] = useState({});
  const [employeeInformation, setEmployeeInformation] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await AdminAPI.get(`/shop/get-branch-details/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
        .then((res) => {
          setBranchDetailsInformation(res.data.data);
          setEmployeeInformation(
            res.data.data.assignedUser.map((el, i) => ({
              key: i,
              ...el,
            }))
          );
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            ErrorHandler(error?.response?.data?.message, history);
          } else {
            Notification('Something went wrong! Please check your internet connection', 'error');
          }
        })
        .finally(() => setLoading(false));
    })();
  }, []);

  const toggleEmployeeStatus = (record, e) => {
    let status = !e;
    setLoadingSwitch(true);
    AdminAPI.patch(
      '/shop/toggle-employee',
      { user_id: record.id, status: status },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
      .then((response) => {
        if (response.data.success) record.delete_marker = status;
        let value = employeeInformation;

        value[value.findIndex((obj) => obj.id == record.id)].delete_marker = status;
        setEmployeeInformation([...value]);
        Notification('Done', 'Employee status updated successfully', 'success');
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Error', 'Error occurred while updating employee status. Please try again later', 'error');
        }
      })
      .finally(() => setLoadingSwitch(false));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Contact Number',
      dataIndex: 'contact_no',
      render: (text) => `0${text}`,
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Status',
      dataIndex: 'delete_marker',
      render: (text, record) => (
        <Switch
          size='small'
          defaultChecked={!record.delete_marker}
          onChange={(e) => {
            toggleEmployeeStatus(record, e);
          }}
          disabled={loadingSwitch || hasAccess(39) ? false : true}
          key={record.id}
          checked={!employeeInformation[employeeInformation.findIndex((obj) => obj.id === record.id)].delete_marker}></Switch>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Title level={3} align='center'>
        Branch Details
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card>
            <Descriptions title='Branch Information' bordered>
              <Descriptions.Item label='Branch Name'>{branchDetailsInformation?.branches?.branch_name}</Descriptions.Item>
              <Descriptions.Item label='Channel'>{branchDetailsInformation?.branches?.display_label}</Descriptions.Item>
              <Descriptions.Item label='Address'>{branchDetailsInformation?.branches?.address}</Descriptions.Item>
              <Descriptions.Item label='BHN ID'>{branchDetailsInformation?.branches?.bhn_id}</Descriptions.Item>
              <Descriptions.Item label='Web Address'>{branchDetailsInformation?.branches?.web_address}</Descriptions.Item>
              <Descriptions.Item label='Years of Operation'>{branchDetailsInformation?.branches?.years_of_operation}</Descriptions.Item>
              <Descriptions.Item label='Floor Space Sqft.'>{branchDetailsInformation?.branches?.floor_space_sqft}</Descriptions.Item>
              <Descriptions.Item label='TP Score'>{branchDetailsInformation?.branches?.tp_score}</Descriptions.Item>
              <Descriptions.Item label='Branch Creation Time'>{moment(branchDetailsInformation?.branches?.createdAt).format('YYYY-MM-DD h:mm:ss')}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24}>
          <Card>
            <Descriptions title='Location Information' bordered>
              <Descriptions.Item label='Region'>{branchDetailsInformation?.locs?.region}</Descriptions.Item>
              <Descriptions.Item label='Area'>{branchDetailsInformation?.locs?.area}</Descriptions.Item>
              <Descriptions.Item label='Territory'>{branchDetailsInformation?.locs?.territory}</Descriptions.Item>
              <Descriptions.Item label='Distribution Point'>{branchDetailsInformation?.locs?.dp}</Descriptions.Item>
              <Descriptions.Item label='Location'>{branchDetailsInformation?.locs?.location}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24}>
          <Card title='Assigned Employee Information'>
            <Table columns={columns} dataSource={employeeInformation} pagination={false} />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default ShopDetails;
