import { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Row, Col, Spin, TreeSelect } from 'antd';
import { useHistory } from 'react-router-dom';

import ErrorHandler from '../../utils/ErrorHandler';
import Notification from '../../components/controls/Notification';
import { AdminAPI } from '../../utils/Api';
import { useParams } from 'react-router-dom';

const { SHOW_PARENT } = TreeSelect;
const { TextArea } = Input;
const { Option } = Select;

const EditResource = () => {
  const history = useHistory();
  const [editResourceForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [parentList, setParentList] = useState([]);
  const { id } = useParams();

  /**
   * editResourceForm.setFieldsValue({
   *        type: res.data.data.resource_type
   *  })
   *
   */

  const fetchResourceType = async () => {
    try {
      const res = await AdminAPI.get('/role/get-resource-type', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      setResourceTypes(res.data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchResourceType();

    AdminAPI.get(`/role/get-resource/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        handleResourceChange(res.data.data.resource_type);
        editResourceForm.setFieldsValue({
          display_label: res.data.data.display_label,
          type: res.data.data.resource_type,
          description: res.data.data.resource_description,
          parent: res.data.data.parent,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const handleResourceChange = (value) => {
    AdminAPI.post(
      '/role/get-parent-by-resource',
      { type: value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    )
      .then((res) => {
        setParentList(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
        }
      });
  };

  const onFinish = async ({ display_label, description, parent, type }) => {
    setLoading(true);

    const body = {
      id: id,
      display_label,
      resource_type: type,
      resource_description: description,
      parent: parent,
    };
    AdminAPI.patch(`/role/update-resource/${id}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(() => {
        Notification('Success', 'Resource edited successfully!', 'success');
        history.push('/role-manager/resource-list');
        editResourceForm.resetFields();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Spin spinning={loading}>
      <h1 className='title'>Edit Resource</h1>
      <Form
        name='add-resource'
        initialValues={{
          remember: true,
        }}
        form={editResourceForm}
        layout='vertical'
        onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Display Label'
              name='display_label'
              rules={[
                {
                  required: true,
                  message: 'Please input a display name!',
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label='Type'
              name='type'
              rules={[
                {
                  required: true,
                  message: 'Please select a resource type!',
                },
              ]}>
              <Select placeholder='Select a type' optionFilterProp='children' onChange={handleResourceChange}>
                {resourceTypes?.map((resource) => (
                  <Option value={resource.id} key={resource.id}>
                    {resource.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={24}>
            <Form.Item label='Description' name='description'>
              <TextArea showCount maxLength={100} allowClear rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Parent'
              name='parent'
              rules={[
                {
                  required: true,
                  message: 'Please select a parent!',
                },
              ]}>
              <Select placeholder='Select parent' optionFilterProp='children'>
                {parentList?.map((el) => (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className='submit-btn'>
          <Button type='primary' htmlType='submit'>
            Edit Resource
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default EditResource;
