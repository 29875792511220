import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/controls/Notification';
import { AdminAPI, SalesAPI } from '../../utils/Api';
import ErrorHandler from '../../utils/ErrorHandler';
import { Form, Input, Button, Select, Row, Col, Divider, Spin, Upload } from 'antd';
import { LockOutlined, MailOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { mobileNumberValidation } from './../../utils/Validations';
import './../../styles/PageStyles/CreateUser.less';

const { Option } = Select;
const { TextArea } = Input;

const CreateUser = () => {
  const [createUserForm] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [allUsersEmail, setAllUsersEmail] = useState([]);
  const [allUsersContact, setAllUsersContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [branchDisable, setBranchDisable] = useState(true);
  const [roleType, setroleType] = useState();

  const history = useHistory();

  const fetchRole = async () => {
    try {
      const { data } = await AdminAPI.get('/user/role-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setRoleList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const fetchShop = async () => {
    try {
      const { data } = await AdminAPI.get('/shop/shop-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setShopList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const fetchBranch = async (shop_id) => {
    try {
      const { data } = await AdminAPI.get(`/shop/branch-list/${shop_id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setBranchList(data.data);
      let ids = data.data.map((v) => v.id);
      if (branchDisable === true) {
        createUserForm.setFieldsValue({
          branch_id: ids,
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  useEffect(() => {
    fetchShop();
    fetchRole();

    (async () => {
      try {
        const { data } = await AdminAPI.get('/user/user-list', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });
        setAllUsersEmail(data?.data?.map((val) => val.email));
        setAllUsersContact(data?.data?.map((val) => '0' + val.contact_no));
      } catch (error) {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
          Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  const onShopChange = (val) => {
    if (val) {
      fetchBranch(val);
    } else {
      setBranchList([]);
      createUserForm.setFieldsValue({
        branch_id: [],
      });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Notification('Error', 'You can only upload JPG/PNG file.', 'error');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Notification('Error', 'Image must smaller than 5MB.', 'error');
    }

    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onRoleChange = (val) => {
    if (val) {
      let role = roleList.filter((v) => v.id == val);
      setroleType(role[0].type);
      createUserForm.setFieldsValue({
        shop_id: null,
        branch_id: [],
      });
      if (role[0].type == 66) {
        setBranchDisable(true);
      } else {
        setBranchDisable(false);
      }
    } else {
      setBranchDisable(false);
    }
  };

  const onFinish = async (values) => {
    values.photo = imageUrl;
    values.type = roleType;
    setLoading(true);
    try {
      const { data } = await AdminAPI.post('/user/create', values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      setLoading(false);
      if (data.message == 'user exist') {
        Notification('User already Exist with this phone number or email', '', 'warning');
      } else {
        Notification('User created successfully', '', 'success');
        createUserForm.resetFields();
        history.push('/user-manager/view-user');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  return (
    <Spin spinning={loading}>
      {/* <Title className="title" level={3}>Create User</Title> */}
      <h1 className='title'> Create User </h1>
      <Form
        name='create-user'
        initialValues={{
          remember: true,
        }}
        form={createUserForm}
        layout='vertical'
        onFinish={onFinish}>
        <Divider orientation='left'>Basic Information</Divider>
        <br />
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label='Phone Number'
              name='contact_no'
              autoComplete='off'
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number',
                },
                {
                  pattern: mobileNumberValidation,
                  message: 'Phone number is invalid',
                },
                {
                  validator: (_, value) => (!allUsersContact?.includes(value) ? Promise.resolve() : Promise.reject('Phone  number is already taken by another user')),
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label='Email(will be used as user id)'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please input your email address',
                },
                {
                  type: 'email',
                  message: 'Invalid email address',
                },
                {
                  validator: (_, value) => (!allUsersEmail?.includes(value) ? Promise.resolve() : Promise.reject('Email is already taken by another user')),
                },
              ]}>
              <Input prefix={<MailOutlined className='site-form-item-icon' />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8} xs={24}>
            <Form.Item
              label='Address'
              name='address'
              // rules={[
              //     {
              //         required: true,
              //         message: "Please input your address",
              //     },
              // ]}
            >
              <TextArea allowClear={true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
                // {
                //     pattern: passwordValidation,
                //     message: "Please enter a strong password",
                // },
              ]}>
              <Input.Password prefix={<LockOutlined className='site-form-item-icon' />} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={4} md={4} xl={4}>
            <div className='flex-container'>
              <p>Add Photo</p>
              <Upload
                name='Users'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                action={(file) => {
                  let data = new FormData();
                  data.append('Users', file);
                  setLoading(true);

                  SalesAPI.post('/upload/files', data, {
                    headers: { 'Content-Type': `multipart/form-data;` },
                  })
                    .then((response) => {
                      setImageUrl(`${response.data.file_name}`);
                      setLoading(false);
                    })
                    .catch((err) => {
                      Notification('Error', 'Could not upload photo', 'error');
                      setLoading(false);
                    });
                }}
                beforeUpload={beforeUpload}>
                {imageUrl ? <img src={process.env.REACT_APP_s3_cdn + imageUrl} alt='avatar' style={{ maxWidth: '75%' }} /> : uploadButton}
              </Upload>
            </div>
          </Col>
          <Col xs={24} sm={4} md={4} xl={4} style={{ display: 'flex', alignItems: 'center' }}>
            <div className='verticalAlign'>
              1. The image must be clear and recent <br />
              2. Photo must be passport size (4x4)
              <br />
              3. Size must not exceed 5mb. <br />
              4. Image must be JPG/PNG file.
              <br />
            </div>
          </Col>
        </Row>
        <Divider orientation='left'>Assignment</Divider>
        <br />
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Role'
              name='role_id'
              rules={[
                {
                  required: true,
                  message: 'Please select a role',
                },
              ]}>
              <Select
                allowClear
                showArrow
                showSearch
                placeholder='select role'
                optionFilterProp='children'
                onChange={onRoleChange}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                {roleList.map((role) => {
                  return (
                    <Option value={role.id} key={role.id}>
                      {role.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {roleType == 66 || roleType == 2 ? (
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label='Shop'
                name='shop_id'
                rules={[
                  {
                    required: true,
                    message: 'Please select a shop',
                  },
                ]}>
                <Select
                  onChange={onShopChange}
                  allowClear
                  showArrow
                  showSearch
                  placeholder='select shop'
                  optionFilterProp='children'
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {shopList.map((val) => {
                    return (
                      <Option value={val.id} key={val.id}>
                        {val.shop_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label='Branch'
                name='branch_id'
                rules={[
                  {
                    required: true,
                    message: 'Please select a branch',
                  },
                ]}>
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder='select branch'
                  disabled={branchDisable}
                  mode={branchDisable ? 'multiple' : 'single'}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {branchList.map((val) => {
                    return (
                      <Option value={val.id} key={val.id}>
                        {val.branch_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <Row justify='center'>
          <Col>
            <Button block size='large' type='primary' htmlType='submit'>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateUser;
