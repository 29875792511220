import "./styles/App.less";
import CommonLayout from "./layouts/CommonLayout";

const App = () => {
  return (
    <>
      <CommonLayout />;
    </>
  );
};

export default App;
