import React from "react";
import { Link } from "react-router-dom";
import { Card, Typography } from "antd";
import {
  IoShieldCheckmark,
  IoPeopleSharp,
  IoStorefront,
  IoPieChart,
  IoDesktop,
  IoBagHandle,
} from "react-icons/io5";

import "../styles/PageStyles/Home.less";
import { hasAccess } from "../utils/Helper";

const { Title } = Typography;

const Home = () => {
  return (
    <>
      <Title className="center-text">Welcome to Sentinel Admin</Title>

      <div className="center-content-row">
        {hasAccess(23) && (
          <Card className="card-content" style={{ backgroundColor: "#69f0ae" }}>
            <Link to="/role-manager/role-list">
              <div className="card-texts">
                <span className="card-header">Role Manager</span>
                <p>
                  Access management for the users depending on his role and
                  location level access
                </p>
              </div>
              <IoShieldCheckmark className="card-icon" />
            </Link>
          </Card>
        )}
        {hasAccess(27) && (
          <Card
            className="card-content"
            style={{ backgroundColor: "lightblue" }}
          >
            <Link to="/user-manager/view-user">
              <div className="card-texts">
                <span className="card-header">User Manager</span>
                <p>Manage users, create, update, delete existing users</p>
              </div>
              <IoPeopleSharp className="card-icon" />
            </Link>
          </Card>
        )}
        {hasAccess(29) && (
          <Card className="card-content" style={{ backgroundColor: "#CC94EC" }}>
            <Link to="/shop-manager/view-shop">
              <div className="card-texts">
                <span className="card-header">Shop Manager</span>
                <p>
                  Shop management for the users depending on his role and
                  location level access
                </p>
              </div>
              <IoStorefront className="card-icon" />
            </Link>
          </Card>
        )}
        {hasAccess(20) && (
          <Card className="card-content" style={{ backgroundColor: "#FCDC04" }}>
            <Link to="/report-manager/one-view-report">
              <div className="card-texts">
                <span className="card-header">Report Manager</span>
                <p>
                  Create and manage reports including access to those reports
                </p>
              </div>
              <IoPieChart className="card-icon" />
            </Link>
          </Card>
        )}
        {hasAccess(21) && (
          <Card className="card-content" style={{ backgroundColor: "#04BCFC" }}>
            <Link to="/dashboard-manager/dashboards">
              <div className="card-texts">
                <span className="card-header">Dashboard Manager</span>
                <p>
                  Access available dashboard, create and manage those dashboards
                </p>
              </div>
              <IoDesktop className="card-icon" />
            </Link>
          </Card>
        )}

        {hasAccess(53) && (
          <Card className="card-content" style={{ backgroundColor: "#FC94AC" }}>
            <Link to="/stock-manager/stock-lists">
              <div className="card-texts">
                <span className="card-header">Stock Manager</span>
                <p>
                  User can allocate stock of event type shop & view current
                  stock of a branch
                </p>
              </div>
              <IoBagHandle className="card-icon" />
            </Link>
          </Card>
        )}
      </div>
    </>
  );
};

export default Home;
