import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Notification from "../../components/controls/Notification";
import { AdminAPI } from "../../utils/Api";
import ErrorHandler from "../../utils/ErrorHandler";
import { Form, Input, Button, Select, Row, Col, InputNumber, Spin, AutoComplete } from "antd";
import "./../../styles/PageStyles/CreateUser.less";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import LocationFields from "./LocationFields";
import { AppRootContext } from "../../contexts/AppRootContext";
import { mobileNumberValidation } from "../../utils/Validations";

const { Option } = Select;
const { TextArea } = Input;

const EditBranch = () => {
  const [createUserForm] = Form.useForm();
  const [presenceList, setPresenceList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [tpList, setTpList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [channel, setChannel] = useState([]);
  const [locations, setLocations] = useState([]);
  const [branchLoc, setbranchLoc] = useState([]);
  const { id } = useParams();
  const { locationList } = useContext(AppRootContext);

  const history = useHistory();

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult([".com", ".org", ".net", ".in"].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const fetcChannelList = async () => {
    try {
      const { data } = await AdminAPI.get("/report/channel-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setChannel(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  const fetchShop = async () => {
    try {
      const { data } = await AdminAPI.get("/shop/shop-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setShopList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  const fetchPresence = async () => {
    try {
      const { data } = await AdminAPI.get("/shop/presence-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setPresenceList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  const fetchTp = async () => {
    try {
      const { data } = await AdminAPI.get("/shop/tp-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setTpList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  const getBranchInfo = async () => {
    setLoading(true);
    try {
      const { data } = await AdminAPI.get(`/shop/get-branch-info/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const state = data.data.branches;

      createUserForm.setFieldsValue({
        branch_name: state.branch_name,
        branch_email: state.branch_email,
        branch_contact_no: state.branch_contact_no && `0${state.branch_contact_no}`,
        shop_id: state.shop_id,
        web_address: state?.web_address?.replace("https://", ""),
        address: state.address,
        branch_presence: state?.branch_presence,
        tp_scope: state?.tp_scope,
        floor_space_sqft: state.floor_space_sqft,
        years_of_operation: state.years_of_operation,
        channel_id: state.channel,
        bhn_id: state.bhn_id,
        //  region: [state?.reg_id],
        //  vape_location: state?.loc_id
      });
      setbranchLoc(data.data.locs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  useEffect(() => {
    getBranchInfo();
    fetchShop();
    fetchPresence();
    fetchTp();
    fetcChannelList();
  }, []);

  const onFinish = async (values) => {
    values.id = id;
    let loc_map_id = locations.filter((v) => v.loc_id === values.vape_location)[0].loc_map_id;
    values.dp_loc_maps_id = loc_map_id;
    if (values.branch_contact_no) {
      values.branch_contact_no = values.branch_contact_no.substring(1);
    } else {
      delete values.branch_contact_no;
    }

    // values.branch_contact_no = values.branch_contact_no.substring(1);
    setLoading(true);
    try {
      const { data } = await AdminAPI.post("/shop/update-branch-info", values, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setLoading(false);
      Notification("Branch updated successfully", "", "success");
      createUserForm.resetFields();
      history.push("/shop-manager/view-branch");
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ padding: "20px" }}>
        <h1 style={{ textAlign: "center", fontSize: "30px", color: "#0066df" }}> Edit Branch </h1>
        <Form
          name="create-user"
          initialValues={{
            remember: true,
          }}
          form={createUserForm}
          layout="vertical"
          onFinish={onFinish}>
          <LocationFields form={createUserForm} locations={locations} setLocations={setLocations} edit={true} branchLoc={branchLoc} setbranchLoc={setbranchLoc} />
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                label="Name"
                name="branch_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Branch Email"
                name="branch_email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Branch Contact Number"
                name="branch_contact_no"
                rules={[
                  {
                    pattern: mobileNumberValidation,
                    message: "Please enter valid phone number",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item
                label="Shop"
                name="shop_id"
                rules={[
                  {
                    required: true,
                    message: "Please select a shop",
                  },
                ]}>
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder="select shop"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {shopList.map((val) => {
                    return (
                      <Option value={val.id} key={val.id}>
                        {val.shop_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Web Address"
                name="web_address"
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your shop web address!",
                //     },
                // ]}
              >
                <AutoComplete options={websiteOptions} onChange={onWebsiteChange}>
                  <Input addonBefore="https://" />
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Address"
                name="address"
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your address",
                //     },
                // ]}
              >
                <TextArea allowClear={true} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="years_of_operation" label="Years of Operation" rules={[{ type: "number", min: 0 }]}>
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="floor_space_sqft" label="Floor Space Sq. ft" rules={[{ type: "number", min: 0 }]}>
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8} xs={12}>
              <Form.Item
                label="Branch Presence"
                name="branch_presence"
                rules={[
                  {
                    required: true,
                    message: "Please select",
                  },
                ]}>
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder="select presence"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {presenceList.map((role) => {
                    return (
                      <Option value={role.id} key={role.id}>
                        {role.display_label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} xs={12}>
              <Form.Item
                label="Tp Scope"
                name="tp_scope"
                rules={[
                  {
                    required: true,
                    message: "Please select a tp scope",
                  },
                ]}>
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder="select tp scope"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {tpList.map((val) => {
                    return (
                      <Option value={val.id} key={val.id}>
                        {val.display_label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }}>
              <Form.Item label="Channel" name="channel_id" rules={[{ required: true }]}>
                <Select placeholder="Please select channel" style={{ width: "100%" }} allowClear showArrow maxTagCount={4}>
                  {/* <Option value={"all"}>Select All</Option> */}
                  {channel.map((v, i) => (
                    <Option value={v.id} key={i}>
                      {" "}
                      {v.display_label}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="BHN ID "
                name="bhn_id"
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your shop's bhn id !",
                //     },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Button block size="large" type="primary" htmlType="submit">
                Edit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default EditBranch;
