import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin, Table, Space, Row, Col, Input, Tooltip, Switch, Modal, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { GrTrash } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import FuzzySearch from 'fuzzy-search';

import ErrorHandler from '../../utils/ErrorHandler';
import { AdminAPI } from '../../utils/Api';
import Notification from '../../components/controls/Notification';
import { hasAccess } from '../../utils/Helper';

const { confirm } = Modal;

const RoleList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [data, setData] = useState([]);
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role Description',
      dataIndex: 'description',
      key: 'description',
      width: '500px',
      render: (_, record) => (
        <>
          {record.description ? (
            <Tooltip placement='topLeft' title={record.description}>
              <p style={{ marginBottom: 0 }}>{record.description?.length <= 40 ? record.description : record.description.slice(0, 40).trim() + '...'}</p>
            </Tooltip>
          ) : (
            'Description not available'
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'active_status',
      width: '10%',
      key: 'active_status',
      render: (_, record) => (
        <Tooltip title='Status'>
          <Switch
            size='small'
            defaultChecked={record.active_status}
            onChange={(e) => {
              changeStatus(record, e);
            }}
            disabled={loadingSwitch || hasAccess(37) ? false : true}
            key={record.id}
            checked={filterData[filterData.findIndex((obj) => obj.id === record.id)].active_status}></Switch>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          {hasAccess(37) && (
            <Tooltip title='Edit Role'>
              <EditOutlined
                style={{
                  color: '#2daee5',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={() => history.push(`/role-manager/edit-role/${record.id}`)}
              />
            </Tooltip>
          )}
          {hasAccess(38) && (
            <Tooltip title='Delete'>
              <Button type='text' className='icon-color__error' onClick={() => showConfirm(record)}>
                <GrTrash />
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await AdminAPI.get('/role/role-list', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });

        const info = res.data.data.map((el, i) => ({ ...el, key: i }));
        setData([...info]);
        setFilterData([...info]);
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  const deleteResource = (record) => {
    AdminAPI.patch(
      '/role/delete-role',
      { id: record.id },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
      .then(() => {
        let value = filterData.filter((val) => val.id !== record.id);
        setFilterData([...value]);
        Notification('Done', 'Role deleted successfully', 'success');
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const changeStatus = (record, e) => {
    let status = e;
    setLoadingSwitch(true);
    AdminAPI.patch(
      '/role/change-role-status',
      { status: status, id: record.id },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
      .then((response) => {
        if (response.data.success) record.active_status = status;
        let value = filterData;

        value[value.findIndex((obj) => obj.id == record.id)].active_status = status;
        setFilterData([...value]);
        Notification('Done', 'Role updated successfully', 'success');
        setLoadingSwitch(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const showConfirm = (record) => {
    confirm({
      title: 'Do you Want to delete it?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        deleteResource(record);
      },

      onCancel() {},
    });
  };

  const searcher = new FuzzySearch(data, ['name'], {
    sort: true,
  });

  const handleSearch = (value) => {
    if (value) {
      const result = searcher.search(value);
      setFilterData([...result]);
    } else {
      setFilterData(data);
    }
  };

  const handleChange = (value) => {
    if (value.target.value) {
      const result = searcher.search(value.target.value);

      setFilterData([...result]);
    } else {
      setFilterData(data);
    }
  };

  return (
    <Spin spinning={loading}>
      <h1 className='title'> Role List </h1>
      <Row justify='end'>
        <Col lg={8} md={12} xs={24} style={{ marginBottom: '1em' }}>
          <Input.Search enterButton onSearch={handleSearch} onChange={handleChange} placeholder='Search with name...' />
        </Col>
        <Col xs={24}>
          <Table columns={columns} dataSource={filterData} scroll={{ x: 1000 }} size='middle' />
        </Col>
      </Row>
    </Spin>
  );
};

export default RoleList;
