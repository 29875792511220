import React, { useState } from "react";
import { Form, Button, Row, Col, Table, DatePicker, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AdminAPI } from "../../utils/Api";
import Notification from "../../components/controls/Notification";
import ErrorHandler from "../../utils/ErrorHandler";
import * as FileSaver from "file-saver";
import "./ReportManager.less";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;

const ByTradeReport = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [exportData, setExportData] = useState([]);

  const onFinish = async (value) => {
    try {
      setLoading(true);
      const from = value.date_range[0].format("YYYY-MM-DD");
      const to = value.date_range[1].format("YYYY-MM-DD");

      setReport([]);
      const { data } = await AdminAPI.post(
        "/report/by-trader-lifting-report",
        { from: from, to: to },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      if (data.data.report.length > 0) {
        setReport([
          ...data.data.report.map((v, i) => {
            return {
              ...v,
            };
          }),
        ]);
        setExportData(data.data.exportReport);
      } else {
        Notification("No Data Found", "", "warning");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, "Please fix this error and try again. Otherwise communicate with the admin", "error");
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  };

  const onExport = (data, item) => {
    const { base64, parts } = data;
    for (let i = 0; i < parts; i++) {
      const blob = new Blob([base64ToArrayBuffer(base64[i])]);
      FileSaver.saveAs(blob, `${item}_part_${i + 1}.xlsx`);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  return (
    <Spin spinning={loading}>
      <div className="reportmanager-div">
        <Form name="report-manager" initialValues={{ remember: true }} layout="vertical" onFinish={onFinish} form={form}>
          <h1 className="title"> By Trader Lifting Report </h1>
          <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Col xs={24} lg={12}>
              <Form.Item label="Select Date" name="date_range" rules={[{ required: true, message: "Please select date!" }]}>
                <RangePicker disabledDate={currDate => currDate.isBefore(moment('2022-06-23'))} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={20} lg={10}>
              <Form.Item style={{ margin: 0 }}>
                <Button shape="round" type="primary" htmlType="submit">
                  Generate Report
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <br />
        </Form>
        {report?.length > 0 && (
          <Row>
            <Col lg={{ span: 3, offset: 0 }} xs={{ span: 20, offset: 4 }}>
              <Button
                style={{ backgroundColor: "green", color: "white" }}
                icon={<DownloadOutlined />}
                onClick={() => onExport(exportData, "By Trader Lifting Report")}
              >
                Export Report
              </Button>
            </Col>
            <br />
            <br />
            <Col span={24}>
              <div className="target-div">
                <Table dataSource={report} loading={loading} scroll={{ x: 1500 }}>
                  <Column title="Shop Name" dataIndex="Shop Name" key="shopname" width={230} />
                  <ColumnGroup title="Devices" className="devices">
                    <Column title="Black" dataIndex="Black" key="black" width={50} />
                    <Column title="Silver" dataIndex="Silver" key="silver" width={50} />
                    <Column title="Gold" dataIndex="Gold" key="gold" width={50} />
                  </ColumnGroup>
                  <ColumnGroup title="Consumables" className="consumables">
                    <Column title="Iced Mango" dataIndex="Iced Mango" key="IcedMango" width={80} />
                    <Column title="Cucumber Fizz" dataIndex="Cucumber Fizz" key="CucumberFizz" width={80} />
                    <Column title="Golden Tobacco" dataIndex="Golden Tobacco" key="GoldenTobacco" width={80} />
                    <Column title="Vanilla Medley" dataIndex="Vanilla Medley" key="VanillaMedley" width={80} />
                    <Column title="Dark Cherry" dataIndex="Dark Cherry" key="DarkCherry" width={80} />
                    <Column title="Crisp Mint" dataIndex="Crisp Mint" key="CrispMint" width={80} />
                  </ColumnGroup>
                  <ColumnGroup title="Replacement" className="replacement">
                    <Column title="Black" dataIndex="Black Replaced" key="ReturnBlack" width={80} />
                    <Column title="Silver" dataIndex="Silver Replaced" key="ReturnSilver" width={80} />
                    <Column title="Gold" dataIndex="Gold Replaced" key="ReturnGold" width={80} />
                  </ColumnGroup>
                  <Column title="Total Product Price" dataIndex="Total Product Price" key="total_sales_in_price" width={80} />
                  <Column title="Promo Reimbursed" dataIndex="Promo Reimbursed" key="promo_disbursement_due" width={80} />
                  <Column title="Total Order Value" dataIndex="Total Order Value" key="total_sales_in_price" width={80} />
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  );
};

export default ByTradeReport;
