import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Select, AutoComplete, Spin } from 'antd';
import { AdminAPI } from '../../../utils/Api';
import Notification from '../../../components/controls/Notification';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const EditShop = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [presenceList, setPresenceList] = useState([]);
  const location = useLocation();

  useEffect(async () => {
    const { state } = location;

    form.setFieldsValue({
      shop_name: state.shop_name,
      shop_owner: state.owner,
      shop_web: state?.web_address?.replace('https://', ''),
      shop_contact: state.contact_no,
      shop_email: state.email,
      shop_bhnid: state.bhn_id,
      shop_tlicense: state.trade_license,
    });
  }, []);

  useEffect(() => {
    fetchPresence();
  }, []);

  const fetchPresence = async () => {
    try {
      const { data } = await AdminAPI.get('/shop/presence-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setPresenceList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue='+880'>
        <Option value='+880'>+880</Option>
      </Select>
    </Form.Item>
  );

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net', '.in'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const onFinish = async (values) => {
    const { state } = location;
    values.id = state.id;

    try {
      setLoading(true);
      const { data } = await AdminAPI.post('/shop/update-shop-info', values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      Notification('Shop information updated Successfully', 'Successful', 'success');
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  return (
    <div>
      <h1 className='title'> Edit Shop </h1>
      <Spin spinning={loading}>
        <Form
          name='create-shop'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          layout='vertical'
          autoComplete='off'
          form={form}>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                label='Shop Name'
                name='shop_name'
                rules={[
                  {
                    required: true,
                    message: 'Please input your shop name!',
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label='Shop Owner'
                name='shop_owner'
                rules={[
                  {
                    required: true,
                    message: 'Please input your shop owner name!',
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label='Shop Web Address'
                name='shop_web'
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your shop web address!",
                //     },
                // ]}
              >
                <AutoComplete options={websiteOptions} onChange={onWebsiteChange}>
                  <Input addonBefore='https://' />
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                label="Shop's Contact Number "
                name='shop_contact'
                rules={[
                  {
                    required: true,
                    message: 'Please input your shop contact no.!',
                  },
                ]}>
                <Input min={1} addonBefore={prefixSelector} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label="Shop's E-mail "
                name='shop_email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your shop email!',
                  },
                  {
                    type: 'email',
                    message: 'Please include a valid Email!',
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Shop's Trade License "
                name='shop_tlicense'
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your shop's trade license !",
                //     },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                label="Shop's Bhn Id "
                name='shop_bhnid'
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your shop's bhn id !",
                //     },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col md={8} xs={12}>
              <Form.Item
                label="Branch Presence"
                name="branch_presence"
                rules={[
                  {
                    required: true,
                    message: "Please select",
                  },
                ]}>
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder="select presence"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {presenceList.map((role) => {
                    return (
                      <Option value={role.id} key={role.id}>
                        {role.display_label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <Row justify='center'>
            <Col>
              <Button block size='large' type='primary' htmlType='submit'>
                Edit
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default EditShop;
