import { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Row, Col, Spin, TreeSelect } from 'antd';
import { AdminAPI } from '../../utils/Api';
import ErrorHandler from '../../utils/ErrorHandler';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/controls/Notification';

const { SHOW_ALL } = TreeSelect;

const { TextArea } = Input;

const { Option } = Select;

const plat = [
  {
    value: 'web',
  },
  {
    value: 'App',
  },
  {
    value: 'Both',
  },
];
const CreateRole = () => {
  const history = useHistory();
  const [createRoleForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedParentRole, setSelectedParentRole] = useState(undefined);
  const [treeData, setTreeData] = useState([]);
  const [roleType, setRoleType] = useState([]);
  const [childRole, setChildRole] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetches = [fetchResourceMap(), fetchRoleType(), fetchChildRole()];
    Promise.all(fetches)
      .then(() => setLoading(false))
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
        }
      });
  }, []);

  const fetchResourceMap = async () => {
    try {
      const res = await AdminAPI.get('/role/get-resource-map', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      setTreeData([...res.data.data]);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
      }
    }
  };
  const fetchRoleType = async () => {
    try {
      const res = await AdminAPI.get('/role/get-role-types', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      setRoleType([...res.data.data]);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
      }
    }
  };
  const fetchChildRole = async () => {
    try {
      const res = await AdminAPI.get('/role/get-role-names', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      setChildRole([...res.data.data]);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
      }
    }
  };

  const onParentRolesChange = (newValue) => {
    setSelectedParentRole(newValue);
  };

  const onFinish = (values) => {
    setLoading(true);
    const resourceList = values.resource.map((el) => el.value);
    const body = {
      role_name: values.role_name,
      role_type: values.type,
      description: values.description,
      platform: values.plat_manager,
      resources: resourceList,
      role_access: values.access,
    };

    AdminAPI.post('/role/create-role', body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        Notification('Success', 'Role created successfully!', 'success');
        history.push('/role-manager/role-list');
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Spin spinning={loading}>
      <h1 className='title'> Create Role </h1>
      <Form
        name='create-user'
        initialValues={{
          remember: true,
        }}
        form={createRoleForm}
        layout='vertical'
        onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Role Name'
              name='role_name'
              rules={[
                {
                  required: true,
                  message: 'Please input role name!',
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label='Role Type'
              name='type'
              rules={[
                {
                  required: true,
                  message: 'Please select a type!',
                },
              ]}>
              <Select placeholder='Select a type' optionFilterProp='children'>
                {roleType.map((el, i) => (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label='Description'
              name='description'
              rules={[
                {
                  required: true,
                  message: 'Please provide description!',
                },
              ]}>
              <TextArea showCount maxLength={100} allowClear rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Resource list'
              name='resource'
              rules={[
                {
                  required: true,
                  message: 'Please select resource list!',
                },
              ]}>
              <TreeSelect
                showSearch
                style={{
                  width: '100%',
                }}
                value={selectedParentRole}
                onChange={onParentRolesChange}
                multiple
                placeholder='Please select'
                allowClear
                treeData={treeData}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly
                treeDefaultExpandAll
                maxTagCount={3}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label='Access List'
              name='access'
              rules={[
                {
                  required: true,
                  message: 'Please select access list!',
                },
              ]}>
              <Select placeholder='Select a type' optionFilterProp='children' mode='multiple' maxTagCount={3} allowClear>
                {childRole.map((el) => (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name='plat_manager' rules={[{ required: true, message: 'Please input platform' }]} labelCol={{ span: 24 }} label='Platform Manager'>
              <Select allowClear showArrow style={{ width: '100%' }} placeholder='Type or select...'>
                {plat.map((v, index) => (
                  <Option value={index} key={index} style={{ textTransform: 'capitalize' }}>
                    {v.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className='submit-btn'>
          <Button type='primary' htmlType='submit'>
            Create Role
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default CreateRole;
