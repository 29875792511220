import Notification from '../components/controls/Notification';

const ErrorHandler = (message, history) => {
  if (message === 'Session Expired') {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  } else if (message === 'System is under maintenance!!') {
    localStorage.clear();
    //history.push('/under-maintenance');
  } else {
    Notification('Snap!', message, 'error');
  }
};

export default ErrorHandler;
