import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Select, Table, DatePicker, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { AdminAPI } from '../../utils/Api';
import Notification from '../../components/controls/Notification';
import ErrorHandler from '../../utils/ErrorHandler';
import * as FileSaver from 'file-saver';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ByChannelOrderReport = () => {
  let history = useHistory();
  const [report, setReport] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [col, setCol] = useState([]);
  const [channel, setChannel] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetcChannelList = async () => {
    try {
      const { data } = await AdminAPI.get('/report/channel-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setChannel(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  useEffect(() => {
    fetcChannelList();
  }, []);

  const onFinish = async (value) => {
    try {
      setLoading(true);
      value.from_date = value.date_range[0].format('YYYY-MM-DD');
      value.to_date = value.date_range[1].format('YYYY-MM-DD');
      value.channel_id = value.channel_id.filter((v) => v !== 'all');
      setReport([]);
      const { data } = await AdminAPI.post('/report/by-channel-order-report', value, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      if (data.data.report.length > 0) {
        setReport([
          ...data.data.report.map((v, i) => {
            return {
              ...v,
            };
          }),
        ]);
        setExportData(data.data.exportReport);
        let temp = data.data.report.map((v, i) => {
          return {
            ...v,
          };
        });

        let col = temp[0];
        let keyarr = Object.keys(col);

        let colArr = keyarr.map((v, i) => {
          return {
            title: v,
            dataIndex: v,
            key: v,
            width: i == 0 ? 230 : 80,
          };
        });

        setCol(colArr);
      } else {
        Notification('No Data Found', 'Please select a running campaign', 'warning');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const onExport = (data, item) => {
    const { base64, parts } = data;
    for (let i = 0; i < parts; i++) {
      const blob = new Blob([base64ToArrayBuffer(base64[i])]);
      FileSaver.saveAs(blob, `${item}_part_${i + 1}.xlsx`);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const onChannelChange = (value) => {
    let obj = {};
    if (value.includes('all')) {
      obj['channel_id'] = channel.map((v) => v.id);
      value = channel.map((v) => v.id);
    }
    form.setFieldsValue(obj);
  };

  return (
    <Spin spinning={loading}>
      <div className='reportmanager-div'>
        <Form name='report-manager' initialValues={{ remember: true }} layout='vertical' onFinish={onFinish} form={form}>
          <h1 className='title'>By Channel Sales Report</h1>

          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item label='Channel' name='channel_id' rules={[{ required: true }]}>
                <Select placeholder='Please select channel' allowClear showArrow maxTagCount={6} mode='multiple' onChange={onChannelChange}>
                  {channel.length > 0 && <Option value={'all'}>Select All</Option>}
                  {channel.map((v, i) => (
                    <Option value={v.id} key={i}>
                      {v.display_label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label='Select Date'
                name='date_range'
                rules={[
                  {
                    type: 'array',
                    required: true,
                    message: 'Please select Date!',
                  },
                ]}>
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Form.Item>
              <Button shape='round' type='primary' htmlType='submit'>
                Generate Report
              </Button>
            </Form.Item>
          </Row>
        </Form>

        {report?.length > 0 && (
          <Row>
            <Col lg={{ span: 3, offset: 0 }} xs={{ span: 20, offset: 4 }}>
              <Button style={{ backgroundColor: 'green', color: 'white' }} icon={<DownloadOutlined />} onClick={() => onExport(exportData, 'By Channel Sales Report')}>
                Export Report
              </Button>
            </Col>
            <br />
            <Col span={24}>
              <div className='target-div'>
                <Table columns={col} dataSource={report} loading={loading} scroll={{ x: 1000 }} />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  );
};

export default ByChannelOrderReport;
