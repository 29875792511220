import React, { useState } from 'react';
import { Form, Input, Button, Layout, Card, Typography, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import '../styles/PageStyles/Login.less';
import ErrorHandler from '../utils/ErrorHandler';
import Notification from '../components/controls/Notification';
import { AuthAPI } from '../utils/Api';
import { emailValidation, mobileNumberValidation } from '../utils/Validations';

const { Content } = Layout;

function Login() {
  const [form] = Form.useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const validatePhoneNumber = (values) => {
    const validatedNumber = mobileNumberValidation.test(values.cred);
    const validatedEmail = emailValidation.test(values.cred);
    if (validatedNumber || validatedEmail) return true;
    Notification('Phone number or email is invalid', 'Not valid', 'warning');
  };

  const onFinish = async (values) => {
    if (validatePhoneNumber(values)) {
      try {
        values.cred = values.cred.toLowerCase();
        values.platform = 'web';
        setLoading(true);
        const { data } = await AuthAPI.post('/sales/admin-login', values);
        localStorage.setItem('id', data.data.id);

        localStorage.setItem('accessToken', data.data.token);
        localStorage.setItem('shop_name', data.data.branch_name);
        localStorage.setItem('name', data.data.name);
        localStorage.setItem('email', data.data.email);
        localStorage.setItem('photo', data.data.photo);
        localStorage.setItem('contact_no', data.data.contact_no);
        localStorage.setItem('address', data.data.address);
        localStorage.setItem('resource_list', data.data.resource_acess);
        localStorage.setItem('role_access', data.data.role_access);
        localStorage.setItem('userRole', data.data.role_id);
        if (data.data.reset_stts === 1) {
          Notification('Success', 'Login successful', 'success');
          history.push('/manager');
        } else {
          history.push('/reset-password');
          Notification('Welcome!', 'Please reset your password You need to give a strong password for better security', 'info');
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Error', 'Please check internet connection or communicate with admin', 'error');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Layout className='login-layout'>
      <Content>
        <Form form={form} name='normal_login' className='login-form' onFinish={onFinish}>
          <Card>
            <Row justify='center'>
              <Typography.Title level={2} style={{ color: '#1890ff' }}>
                Sentinel Admin
              </Typography.Title>
            </Row>
            <br />
            <Form.Item
              name='cred'
              label='Email or Phone Number'
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your E-mail or Phone number!',
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name='password'
              label='Password'
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}>
              <Input.Password />
            </Form.Item>
            <br />
            <Form.Item>
              <Button type='primary' disabled={loading} htmlType='submit' className='login-form-button'>
                {loading && <LoadingOutlined />} Log In
              </Button>
            </Form.Item>
            <Form.Item>
              {/* <div style={{ textAlign: "center" }}>
              <Link className="login-form-forgot" to="/forgot-password">
                <span>
                  <KeyOutlined />
                </span>
                Forgot password?
              </Link>
            </div>
              <div className="fp-divider">
                <Divider />
              </div> */}
            </Form.Item>
          </Card>
        </Form>
      </Content>
    </Layout>
  );
}

export default Login;
