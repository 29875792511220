import axios from "axios";

const AuthAPI= axios.create({
  baseURL: process.env.REACT_APP_nc_auth,
  headers: {
    "Content-Type": "application/json",
  },
});

const SalesAPI = axios.create({
  baseURL: process.env.REACT_APP_nc_sales,
  headers: {
    "Content-Type": "application/json",
  },
});

const AdminAPI = axios.create({
  baseURL: process.env.REACT_APP_nc_admin_backend,
  headers: {
    "Content-Type": "application/json",
  },
});

export { AuthAPI, AdminAPI, SalesAPI  };
