import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import { AdminAPI, SalesAPI } from "../../utils/Api";
import Notification from "../../components/controls/Notification";

function OrderPanel() {
  const [isToggle, setIsToggle] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const getOrderStatus = async () => {
    try {
      const data = await SalesAPI.get("/get-order-status/1", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setIsToggle(data?.data?.data?.status);
    } catch (error) {
      Notification("Error", "Something went wrong.", "error");
    }
  };

  useEffect(() => {
    setIsloading(true);
    getOrderStatus().finally(() => setIsloading(false));
  }, []);

  const handleToggle = async (value) => {
    try {
      setIsloading(true);
      await AdminAPI.patch(
        `/stock/toggle-order`,
        { id: 1, status: value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      Notification("Successful", "Order status has been modified", "success");

      await getOrderStatus();

      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      Notification("Error!", "Something went wrong!!", "error");
    }
  };

  return (
    <>
      <h1 className="title">
        <span style={{ marginRight: ".5rem" }}>Order Panel Status</span>
        <Switch
          checked={isToggle}
          onChange={handleToggle}
          loading={isLoading}
        />
      </h1>
    </>
  );
}

export default OrderPanel;
