import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Space, Tooltip, Input, Spin, Row, Col, Modal } from 'antd';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import FuzzySearch from 'fuzzy-search';

import Notification from '../../../components/controls/Notification';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AdminAPI } from '../../../utils/Api';
import { hasAccess } from '../../../utils/Helper';

const { confirm } = Modal;

const ViewShop = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [shopData, setShopData] = useState([]);

  useEffect(async () => {
    try {
      setLoading(true);
      const { data } = await AdminAPI.get('/shop/shop-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setShopData(data.data);
      setData(data.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  }, []);

  const columns = [
    {
      title: 'Shop Name',
      dataIndex: 'shop_name',
      key: 'shop_name',
    },
    {
      title: 'Shop Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'Web Address',
      dataIndex: 'web_address',
      key: 'web_address',
      render: (text) => (
        <a href={text} target='_blank'>
          {text}
        </a>
      ),
    },
    {
      title: 'Shop Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Contact no.',
      dataIndex: 'contact_no',
      key: 'contact_no',
    },
    {
      title: 'Bhn Id',
      dataIndex: 'bhn_id',
      key: 'bhn_id',
      width: 100,
    },
    {
      title: 'Trade License',
      dataIndex: 'trade_license',
      key: 'trade_license',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          {hasAccess(41) && (
            <Tooltip title='Edit Shop Information'>
              <EditOutlined style={{ color: '#2daee5', fontSize: '1.5em', cursor: 'pointer' }} onClick={() => history.push({ pathname: `/shop-manager/edit-shop/${record.id}`, state: record })} />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  // const deleteShop = (record) => {
  //   confirm({
  //     title: 'Do you want to delete this shop?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'When clicked the OK button, this cannot be reverted',
  //     onOk() {
  //       return AdminAPI.patch(
  //         '/shop/deleteshop',
  //         { id: record.id },
  //         {
  //           headers: {
  //             Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
  //           },
  //         }
  //       )
  //         .then(() => {
  //           let temp = shopData.filter((val) => val.id !== record.id);
  //           setShopData(temp);
  //           let temp2 = data.filter((val) => val.id !== record.id);
  //           setData(temp2);

  //           Notification('Done', 'User deleted successfully', 'success');
  //         })
  //         .catch((error) => {
  //           Notification('Error', 'Error occurred while deleting. Please try again later', 'error');
  //         });
  //     },
  //     onCancel() {},
  //   });
  // };

  const searcher = new FuzzySearch(data, ['shop_name', 'owner', 'web_address', 'email', 'contact_no', 'trade_license'], { sort: true });

  const handleChange = (value) => {
    if (value.target.value) {
      const result = searcher.search(value.target.value);
      setShopData([...result]);
    } else {
      setShopData(data);
    }
  };

  const handleSearch = (value) => {
    if (value) {
      const result = searcher.search(value);
      setShopData([...result]);
    } else {
      setShopData(data);
    }
  };

  return (
    <>
      <h1 className='title'> Shop List </h1>

      <Spin spinning={loading}>
        <Row justify='end'>
          <Col xl={8} md={12} xs={24} style={{ marginBottom: '1em' }}>
            <Input.Search enterButton onSearch={handleSearch} onChange={handleChange} placeholder='Search with name, owner name, web address, email, contact_no or trade license..' />
          </Col>
          <Col xl={24} xs={24}>
            <Table dataSource={shopData} columns={columns} scroll={{ x: 1500 }} />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ViewShop;
