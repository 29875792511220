export const Accesses = [
  {
    url: "/role-manager/create-role",
    access_code: 22,
  },
  {
    url: "/role-manager/edit-role",
    access_code: 37,
  },
  {
    url: "/role-manager/role-list",
    access_code: 23,
  },
  {
    url: "/role-manager/add-resource",
    access_code: 24,
  },
  {
    url: "/role-manager/resource-list",
    access_code: 25,
  },
  {
    url: "/role-manager/edit-resource",
    access_code: 35,
  },
  {
    url: "/user-manager/create-user",
    access_code: 26,
  },
  {
    url: "/user-manager/view-user",
    access_code: 27,
  },
  {
    url: "/user-manager/edit-user",
    access_code: 39,
  },
  {
    url: "/shop-manager/create-shop",
    access_code: 28,
  },
  {
    url: "/shop-manager/view-shop",
    access_code: 29,
  },
  {
    url: "/shop-manager/edit-shop",
    access_code: 41,
  },
  {
    url: "/shop-manager/create-branch",
    access_code: 30,
  },
  {
    url: "/shop-manager/view-branch",
    access_code: 31,
  },
  {
    url: "/shop-manager/edit-branch",
    access_code: 42,
  },
  {
    url: "/report-manager/by-channel-order",
    access_code: 45,
  },
  {
    url: "/report-manager/by-trader-sales",
    access_code: 46,
  },
  {
    url: "/report-manager/by-trade-lifting-report",
    access_code: 47,
  },
  {
    url: "/report-manager/on-hand-stock-report",
    access_code: 48,
  },
  {
    url: "/report-manager/registered-users",
    access_code: 51,
  },
  {
    url: "/report-manager/by-consumer-sales",
    access_code: 50,
  },
  {
    url: "/report-manager/one-view-report",
    access_code: 49,
  },
  {
    url: "/report-manager/sob-report",
    access_code: 59,
  },
  {
    url: "/dashboard-manager/dashboards",
    access_code: 34,
  },
  {
    url: "/dashboard-manager/dashboard-card",
    access_code: 34,
  },
  {
    url: "/dashboard-manager/create-dashboard",
    access_code: 32,
  },
  {
    url: "/dashboard-manager/update-dashboard",
    access_code: 43,
  },
  {
    url: "/dashboard-manager/dashboard-list",
    access_code: 33,
  },
  {
    url: "/stock-manager/allocate-stock",
    access_code: 54,
  },
  {
    url: "/stock-manager/stock-lists",
    access_code: 55,
  },
  {
    url: "/stock-manager/edit-allocated-stock",
    access_code: 56,
  },
  {
    url: "/stock-manager/normal-branch-stock",
    access_code: 57,
  },
  {
    url: "/shop-manager/branch-details",
    access_code: 58,
  },
  {
    url: "/stock-status/toggle",
    access_code: 60,
  },
];
