import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Space, Tooltip, Input, Spin, Row, Col } from "antd";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import FuzzySearch from "fuzzy-search";

import Notification from "../../components/controls/Notification";
import ErrorHandler from "../../utils/ErrorHandler";
import { AdminAPI } from "../../utils/Api";
import { hasAccess } from "../../utils/Helper";

const ViewBranch = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [branchData, setBranchData] = useState([]);

  useEffect(async () => {
    try {
      setLoading(true);
      const { data } = await AdminAPI.get("/shop/all-branch-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      let temp = data?.data?.map((v, i) => {
        return {
          ...v,
          key: i + 1,
          tp_scope: v.tpscope?.display_label || "",
          branch_presence: v.branchpresence?.display_label || " ",
          web_address: v.web_address ? `https://${v.web_address}` : "",
          channel: v.channelid.display_label,
          shop_name: v.shop.shop_name,
        };
      });
      setBranchData(temp);
      setData(temp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification("Something went wrong", "Please check your internet connection and try again or communicate with the admin", "error");
      }
    }
  }, []);

  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
    },
    {
      title: "Shop Name",
      dataIndex: "shop_name",
      key: "shop_name",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Branch Presence",
      dataIndex: "branch_presence",
      key: "branch_presence",
    },
    {
      title: "Tp Scope",
      dataIndex: "tp_scope",
      key: "tp_scope",
    },
    // {
    //   title: 'Web Address',
    //   dataIndex: 'web_address',
    //   key: 'web_address',
    //   render: (text) => (
    //     <a href={text} target='_blank'>
    //       {text}
    //     </a>
    //   ),
    // },
    // {
    //   title: 'Years of Operation',
    //   dataIndex: 'years_of_operation',
    //   key: 'years_of_operation',
    // },
    // {
    //   title: 'Floor Space Sq. ft',
    //   dataIndex: 'floor_space_sqft',
    //   key: 'floor_space_sqft',
    // },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {hasAccess(58) && (
            <Tooltip title="View Branch Information">
              <FileTextOutlined
                style={{ color: "orange", fontSize: "1.5em", cursor: "pointer" }}
                onClick={() => history.push({ pathname: `/shop-manager/branch-details/${record.id}`, state: record })}
              />
            </Tooltip>
          )}
          {hasAccess(42) && (
            <Tooltip title="Edit Branch Information">
              <EditOutlined style={{ color: "#2daee5", fontSize: "1.5em", cursor: "pointer" }} onClick={() => history.push({ pathname: `/shop-manager/edit-branch/${record.id}`, state: record })} />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  // const deletebranch = (record) => {
  //   confirm({
  //     title: 'Do you want to delete this branch?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'When clicked the OK button, this cannot be reverted',
  //     onOk() {
  //       return AdminAPI.patch(
  //         '/branch/deleteshop',
  //         { id: record.id },
  //         {
  //           headers: {
  //             Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
  //           },
  //         }
  //       )
  //         .then(() => {
  //           let temp = branchData.filter((val) => val.id !== record.id);
  //           setBranchData(temp);
  //           let temp2 = data.filter((val) => val.id !== record.id);
  //           setData(temp2);

  //           Notification('Done', 'User deleted successfully', 'success');
  //         })
  //         .catch((error) => {
  //           Notification('Error', 'Error occurred while deleting. Please try again later', 'error');
  //         });
  //     },
  //     onCancel() {},
  //   });
  // };

  const searcher = new FuzzySearch(data, ["branch_name", "branch_presence"], { sort: true });

  const handleChange = (value) => {
    if (value.target.value) {
      const result = searcher.search(value.target.value);
      setBranchData([...result]);
    } else {
      setBranchData(data);
    }
  };

  const handleSearch = (value) => {
    if (value) {
      const result = searcher.search(value);
      setBranchData([...result]);
    } else {
      setBranchData(data);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <h1 className="title"> View Branch </h1>
        <Row justify="end">
          <Col xl={8} md={12} xs={24} style={{ marginBottom: "1em" }}>
            <Input.Search onSearch={handleSearch} enterButton onChange={handleChange} placeholder="Search with name, owner name, web address, email, contact_no or trade license.." />
          </Col>
          <Col xs={24}>
            <Table dataSource={branchData} columns={columns} scroll={{ x: 1500 }} />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ViewBranch;
