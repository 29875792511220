import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, Select, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Notification from '../../components/controls/Notification';
import ErrorHandler from '../../utils/ErrorHandler';
import { AdminAPI } from '../../utils/Api';
import { numberValidation } from '../../utils/Validations';

const { Option } = Select;

const EditAllocatedStock = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [singleStock, setSingleStock] = useState({});

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchBranch(), fetchProduct()])
      .then(() => fetchSingleProduct())

      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const fetchBranch = () => {
    AdminAPI.get('/stock/get-branch-list', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        setBranchList([...res.data.data]);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
          Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const fetchProduct = () => {
    AdminAPI.get('/stock/get-product-list', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        setProductList([...res.data.data]);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
          Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const fetchSingleProduct = () => {
    AdminAPI.get(`/stock/get-single-stock/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        setSingleStock(res.data.data);
        form.setFieldsValue({
          branch: res.data.data.branch_id,
          product: res.data.data.product_id,
          quantity: res.data.data.unit,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
          Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const onFinish = (val) => {
    setLoading(true);
    const addedBy = localStorage.getItem('id');
    const body = {
      id,
      branch_id: val.branch,
      added_by: addedBy,
      product_id: val.product,
      unit: val.quantity,
    };

    AdminAPI.patch('/stock/update-stock-information', body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(() => {
        Notification('Success', 'Stock edited successfully!', 'success');
        // form.resetFields();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Spin spinning={loading}>
      <h1 className='title'> Edit Allocated Stock </h1>
      <Form
        name='stocklists'
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onFinish}
        layout='vertical'>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label='Edit branch'
              name='branch'
              rules={[
                {
                  required: true,
                  message: 'Please select a branch!',
                },
              ]}>
              <Select placeholder='Branch' optionFilterProp='children' showArrow showSearch>
                {branchList.map((el, i) => (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label='Edit product'
              name='product'
              rules={[
                {
                  required: true,
                  message: 'Please select a product!',
                },
              ]}>
              <Select placeholder='Product' optionFilterProp='children' showArrow showSearch>
                {productList.map((el, i) => (
                  <Option value={el.id} key={el.id}>
                    {el.product_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label='Edit quantity'
              name='quantity'
              rules={[
                {
                  required: true,
                  message: 'Please input quantity!',
                },
                {
                  pattern: numberValidation,
                  message: 'Unit must be valid positive number!',
                },
              ]}>
              <Input placeholder='Quantity' />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className='submit-btn'>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default EditAllocatedStock;
