import React, { useState } from 'react';
import { Form, Button, Row, Col, Table, DatePicker, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { AdminAPI } from '../../utils/Api';
import Notification from '../../components/controls/Notification';
import ErrorHandler from '../../utils/ErrorHandler';
import './ReportManager.less';
import * as FileSaver from 'file-saver';

const { RangePicker } = DatePicker;

const RegisteredUsers = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [col, setCol] = useState([]);

  const onFinish = async (value) => {
    try {
      setLoading(true);
      const from = value.date_range[0].format('YYYY-MM-DD');
      const to = value.date_range[1].format('YYYY-MM-DD');

      setReport([]);
      const { data } = await AdminAPI.post(
        '/report/registered-users',
        { from: from, to: to },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      );
      if (data.data.report.length > 0) {
        setReport([
          ...data.data.report.map((v, i) => {
            return {
              ...v,
            };
          }),
        ]);
        setExportData(data.data.exportReport);
        let temp = data.data.report.map((v, i) => {
          return {
            ...v,
          };
        });

        let col = temp[0];
        let keyarr = Object.keys(col);

        let colArr = keyarr.map((v, i) => {
          return {
            title: v,
            dataIndex: v,
            key: v,
            width: i == 0 ? 230 : 80,
          };
        });

        setCol(colArr);
      } else {
        Notification('No Data Found', '', 'warning');
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const onExport = (data, item) => {
    const { base64, parts } = data;
    for (let i = 0; i < parts; i++) {
      const blob = new Blob([base64ToArrayBuffer(base64[i])]);
      FileSaver.saveAs(blob, `${item}_part_${i + 1}.xlsx`);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  return (
    <Spin spinning={loading}>
      <div className='reportmanager-div'>
        <Form name='report-manager' initialValues={{ remember: true }} layout='vertical' onFinish={onFinish} form={form}>
          <h1 className='title'>Registered Users </h1>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Col xs={24} lg={12}>
              <Form.Item label='Select Date' name='date_range' rules={[{ required: true, message: 'Please select date!' }]}>
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={20} lg={10}>
              <Form.Item style={{ margin: 0 }}>
                <Button shape='round' type='primary' htmlType='submit'>
                  Generate Report
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <br />
        </Form>
        {report?.length > 0 && (
          <Row>
            <Col lg={{ span: 3, offset: 0 }} xs={{ span: 20, offset: 4 }}>
              <Button style={{ backgroundColor: 'green', color: 'white' }} icon={<DownloadOutlined />} onClick={() => onExport(exportData, 'Registered Users Report')}>
                Export Report
              </Button>
            </Col>
            <br />
            <br />
            <Col span={24}>
              <div className='target-div'>
                <Table dataSource={report} columns={col} loading={loading} scroll={{ x: 1500 }} />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  );
};

export default RegisteredUsers;
