import { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Row, Col, Spin, TreeSelect } from 'antd';
import { useHistory } from 'react-router-dom';

import ErrorHandler from '../../utils/ErrorHandler';
import Notification from '../../components/controls/Notification';
import { AdminAPI } from '../../utils/Api';

const { SHOW_PARENT } = TreeSelect;
const { TextArea } = Input;
const { Option } = Select;

const AddResource = () => {
  const history = useHistory();
  const [addResourceForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [parentList, setParentList] = useState([]);

  useEffect(() => {
    setLoading(true);
    AdminAPI.get('/role/get-resource-type', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((res) => {
        setResourceTypes(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const handleResourceChange = (value) => {
    setLoading(true);
    AdminAPI.post('/role/get-parent-by-resource', { type: value }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((res) => {
        setParentList(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection', 'error');
        }
      })
      .finally(() => setLoading(false));
  };

  const onFinish = async ({ display_label, description, parent, type }) => {
    setLoading(true);

    const body = {
      display_label,
      resource_type: type,
      resource_description: description,
      parent,
    };
    AdminAPI.post('/role/create-resource', body, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then(() => {
        Notification('Success', 'Resource created successfully!', 'success');
        history.push('/role-manager/resource-list');
        addResourceForm.resetFields();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Snap!', 'Something went wrong! Please check your internet connection');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Spin spinning={loading}>
      <h1 className='title'>Add Resource</h1>
      <Form
        name='add-resource'
        initialValues={{
          remember: true,
        }}
        form={addResourceForm}
        layout='vertical'
        onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Display Label'
              name='display_label'
              rules={[
                {
                  required: true,
                  message: 'Please input a display name!',
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label='Type'
              name='type'
              rules={[
                {
                  required: true,
                  message: 'Please select a resource type!',
                },
              ]}>
              <Select placeholder='Select a type' optionFilterProp='children' onChange={handleResourceChange}>
                {resourceTypes?.map((resource) => (
                  <Option value={resource.id} key={resource.id}>
                    {resource.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={24}>
            <Form.Item label='Description' name='description' rules={[{ required: true, message: 'Please input description' }]}>
              <TextArea showCount maxLength={100} allowClear rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Parent'
              name='parent'
              rules={[
                {
                  required: true,
                  message: 'Please select a parent!',
                },
              ]}>
              <Select placeholder='Select parent' optionFilterProp='children' showSearch filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                {parentList?.map((el, i) => (
                  <Option value={el.id} key={i}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className='submit-btn'>
          <Button type='primary' htmlType='submit'>
            Add Resource
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddResource;
