
import { useHistory } from "react-router-dom";
import { AuthAPI } from "./Api";

const useLogout = () => {
  const history = useHistory();

  const logout = async () => {
    AuthAPI.get("/auth/logout", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then(() => {
        localStorage.clear();
        history.push("/login");
      })
      .catch(() => {
        localStorage.clear();
        history.push("/login");
      });
  };

  return logout;
};

export default useLogout;
