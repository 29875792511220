import React, { useState, useEffect } from 'react';
import { Spin, Table, Space, Row, Col, Input, Tooltip, Switch, Modal, Button, Typography, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FaUser, FaUserShield } from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';
import { MdAlternateEmail, MdDone } from 'react-icons/md';
import { GiCancel } from 'react-icons/gi';
import { useHistory } from 'react-router-dom';
import FuzzySearch from 'fuzzy-search';

import Notification from '../../components/controls/Notification';
import ErrorHandler from '../../utils/ErrorHandler';
import { AuthAPI, AdminAPI } from '../../utils/Api';
import { hasAccess } from '../../utils/Helper';
import { passwordValidation } from '../../utils/Validations';

import './UserManager.less';

const { Paragraph } = Typography;

const ViewUser = () => {
  const history = useHistory();
  const [passResetForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsModalPasswordVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await AdminAPI.get('user/user-list', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });

        setData(data.data);
        setFilterData(
          data.data.map((v, i) => {
            return {
              ...v,
              key: i + 1,
              roles: v.roles.join(','),
            };
          })
        );
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  const showModal = (data) => {
    setSelectedUser(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showPassModal = (data) => {
    setSelectedUser(data);
    setIsModalPasswordVisible(true);
  };

  const handleResetPassCancel = () => {
    setIsModalPasswordVisible(false);
  };

  const onPasswordResetFinish = async (values) => {
    try {
      setLoading(true);
      await AuthAPI.post('/sales/reset-password', { new_password: values.password, id: selectedUser.id });
      Notification('Done!', 'Password Changed Successfully', 'success');
      setLoading(false);
      setIsModalPasswordVisible(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Error', 'error');
        passResetForm.setFieldsValue({
          password: '',
          confirm: '',
        });
        setLoading(false);
      } else {
        Notification('Something went wrong', 'Please check your connection', 'error');
        passResetForm.setFieldsValue({
          password: '',
          confirm: '',
        });
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone Number',
      dataIndex: 'contact_no',
      key: 'age',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'address',
      width: '20%',
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      key: 'address',
    },
    // {
    //   title: "Assigned Shop",
    //   dataIndex: "assigned_shop",
    //   key: "assigned_shop",
    //   render: (text, record) => (
    //     <Button onClick={() => showModal(record)} type="">
    //       View
    //     </Button>
    //   ),
    // },
    {
      title: 'Status',
      dataIndex: 'stts',
      width: '10%',
      key: 'action',
      render: (text, record) => (
        <Tooltip title='Status'>
          <Switch
            size='small'
            defaultChecked={!record.delete_marker}
            onChange={(e) => {
              record.id != localStorage.getItem('id') ? deleteUser(record, e) : Notification('Warning', 'You cannot disable yourself', 'warning');
            }}
            disabled={loadingSwitch || hasAccess(39) ? false : true}
            key={record.id}
            checked={!filterData[filterData.findIndex((obj) => obj.id === record.id)].delete_marker}></Switch>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          {hasAccess(39) && (
            <Tooltip title='Edit User'>
              <EditOutlined style={{ color: '#2daee5', fontSize: '1.5em', cursor: 'pointer' }} onClick={() => history.push(`/user-manager/edit-user/${record.id}`)} />
            </Tooltip>
          )}
          {hasAccess(40) && (
            <Tooltip title='Reset Password'>
              <Button type='text' className='icon-color__error' onClick={() => showPassModal(record)}>
                <GrPowerReset />
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const deleteUser = (record, e) => {
    let status = !e;
    setLoadingSwitch(true);
    AdminAPI.patch(
      '/user/deleteUser',
      { user_id: record.id, status: status },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
      .then((response) => {
        if (response.data.success) record.delete_marker = status;
        let value = filterData;

        value[value.findIndex((obj) => obj.id == record.id)].delete_marker = status;
        setFilterData([...value]);
        Notification('Done', 'User updated successfully', 'success');
        setLoadingSwitch(false);
      })
      .catch((error) => {
        Notification('Error', 'Error occurred while deleting. Please try again later', 'error');
      });
  };

  const searcher = new FuzzySearch(data, ['name', 'contact_no', 'email'], { sort: true });

  const handleChange = (value) => {
    if (value.target.value) {
      const result = searcher.search(value.target.value);
      setFilterData([...result]);
    } else {
      setFilterData(data);
    }
  };

  const handleSearch = (value) => {
    if (value) {
      const result = searcher.search(value);
      setFilterData([...result]);
    } else {
      setFilterData(data);
    }
  };

  const viewAssignedShopTitle = (
    <Paragraph className='assigned-shop__title'>
      <div className='item'>
        <FaUser className='icon-color' /> Username: <span className='value'>{selectedUser.name}</span>
      </div>
      <div className='item'>
        <FaUserShield className='icon-color' /> Role: <span className='value'>{selectedUser.role_name}</span>
      </div>
      <div className='item'>
        <MdAlternateEmail className='icon-color' /> Email: <span className='value'>{selectedUser.email}</span>
      </div>
    </Paragraph>
  );

  const resetPassTitle = (
    <Paragraph className='assigned-shop__title'>
      <div className='item'>
        <FaUser className='icon-color' /> Username: <span className='value'>{selectedUser.name}</span>
      </div>
      <div className='item'>
        <MdAlternateEmail className='icon-color' /> Email: <span className='value'>{selectedUser.email}</span>
      </div>
    </Paragraph>
  );

  const assignShopColumns = [
    {
      title: 'Shop Name',
      dataIndex: 'shop_name',
      key: 'shop_name',
    },
    {
      title: 'Branch Name',
      dataIndex: 'branch_name',
      key: 'branch_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return <>{record ? <MdDone className='icon-color' /> : <GiCancel className='icon-color' />}</>;
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <Modal
        title={viewAssignedShopTitle}
        visible={isModalVisible}
        onCancel={handleCancel}
        className='assigned-user__modal'
        footer={[
          <Button key='back' onClick={handleCancel} danger type='text'>
            Cancel
          </Button>,
        ]}>
        <Row justify='end'>
          <Col xs={24}>
            <Table columns={assignShopColumns} dataSource={filterData} scroll={{ x: 700 }} size='middle' />
          </Col>
        </Row>
      </Modal>
      <Modal
        title={resetPassTitle}
        visible={isPasswordModalVisible}
        onCancel={handleResetPassCancel}
        footer={[
          <Button key='back' onClick={handleResetPassCancel} danger type='text'>
            Cancel
          </Button>,
        ]}>
        <Form
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onPasswordResetFinish}
          autoComplete='off'
          layout='vertical'
          form={passResetForm}>
          <Form.Item
            label='New Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirm'
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Passwords do not matched!'));
                },
              }),
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type='primary' htmlType='submit'>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <h1 className='title'> User List </h1>
      <Row justify='end'>
        <Col lg={8} md={12} xs={24} style={{ marginBottom: '1em' }}>
          {/* <Search placeholder="Search with name, ohone number or email.." onSearch={(e) => handleSearch(e)}  /> */}
          <Input.Search enterButton onSearch={handleSearch} onChange={handleChange} placeholder='Search with name, phone number or email..' />
        </Col>
        <Col xs={24}>
          <Table columns={columns} dataSource={filterData} scroll={{ x: 1000 }} size='middle' />
        </Col>
      </Row>
    </Spin>
  );
};

export default ViewUser;
