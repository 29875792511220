import React, {  useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Typography,
} from "antd";

import Notification from "../../components/controls/Notification";
import { AdminAPI } from "../../utils/Api";
import ErrorHandler from "../../utils/ErrorHandler";
import moment from "moment";
var FileSaver = require("file-saver");

const SOBReport = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      const res = await AdminAPI.get("/report/sob-report", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const blob = new Blob([base64ToArrayBuffer(res.data.data)]);
      FileSaver.saveAs(blob, `SOB_Report${moment().format("L")}.xlsx`);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
      } else {
        Notification(
          "Could not download SOB report.",
          "Please try again later.",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };
  return (
    <Spin spinning={loading}>
      <div className="reportmanager-div">
        <Form
          name="report-manager"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
        >
          <h1 className="title">SOB Report VUSE</h1>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={24}>
              <Typography.Title level={4}>
                Please click on the below button to download the SOB report of
                till date
              </Typography.Title>
            </Col>
            <Col xs={20}>
              <Form.Item style={{ margin: 0 }}>
                <Button shape="round" type="primary" htmlType="submit">
                  Generate Report
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default SOBReport;
