import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin, Table, Space, Row, Col, Input, Tooltip, Switch, Modal, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { GrTrash } from 'react-icons/gr';
import FuzzySearch from 'fuzzy-search';
import { useHistory } from 'react-router-dom';

import ErrorHandler from '../../utils/ErrorHandler';
import { AdminAPI } from '../../utils/Api';
import Notification from '../../components/controls/Notification';
import { hasAccess } from '../../utils/Helper';

const { confirm } = Modal;

const ResourceList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [data, setData] = useState([]);
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: val } = await AdminAPI.get('/role/resource-list', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });

        setData(
          val.data.map((v, i) => {
            return {
              ...v,
              key: i + 1,
              type: v['_vcat.display_label'],
            };
          })
        );
        setFilterData(
          val.data.map((v, i) => {
            return {
              ...v,
              key: i + 1,
              type: v['_vcat.display_label'],
            };
          })
        );
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  const deleteResource = (record) => {
    AdminAPI.patch(
      '/role/delete-resource',
      { id: record.id },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
      .then((response) => {
        let value = filterData.filter((val) => val.id !== record.id);
        setFilterData([...value]);
        Notification('Done', 'Resource deleted successfully', 'success');
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const changeStatus = (record, e) => {
    let status = e;
    setLoadingSwitch(true);
    AdminAPI.patch(
      '/role/change-status',
      { status: status, id: record.id },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }
    )
      .then((response) => {
        if (response.data.success) record.stts = status;
        let value = filterData;

        value[value.findIndex((obj) => obj.id == record.id)].stts = status;
        setFilterData([...value]);
        Notification('Done', 'Resource updated successfully', 'success');
        setLoadingSwitch(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const showConfirm = (record) => {
    confirm({
      title: 'Do you Want to delete it?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        deleteResource(record);
      },

      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Resource Name',
      dataIndex: 'display_label',
      key: 'display_label',
    },
    {
      title: 'Resource Description',
      dataIndex: 'resource_description',
      key: 'resource_description',
      width: '300px',
      render: (_, record) => (
        <>
          <Tooltip placement='topLeft' title={record.resource_description}>
            <p style={{ marginBottom: 0 }}>{record.resource_description.length <= 40 ? record.resource_description : record.resource_description.slice(0, 40).trim() + '...'}</p>
          </Tooltip>
        </>
      ),
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      key: 'status',
      render: (_, record) => (
        <Tooltip title='Status'>
          <Switch
            size='small'
            defaultChecked={record.stts}
            onChange={(e) => {
              changeStatus(record, e);
            }}
            disabled={loadingSwitch || hasAccess(35) ? false : true}
            key={record.id}
            checked={filterData[filterData.findIndex((obj) => obj.id === record.id)].stts}></Switch>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          {hasAccess(35) && (
            <Tooltip title='Edit Role'>
              <EditOutlined style={{ color: '#2daee5', fontSize: '1.5em', cursor: 'pointer' }} onClick={() => history.push(`/role-manager/edit-resource/${record.id}`)} />
            </Tooltip>
          )}

          {hasAccess(36) && (
            <Tooltip title='Delete'>
              <Button type='text' className='icon-color__error' onClick={() => showConfirm(record)}>
                <GrTrash />
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const searcher = new FuzzySearch(data, ['id', 'display_label', 'type'], {
    sort: true,
  });
  const handleSearch = (value) => {
    if (value) {
      const result = searcher.search(value);
      setFilterData([...result]);
    } else {
      setFilterData(data);
    }
  };
  const handleChange = (value) => {
    if (value.target.value) {
      const result = searcher.search(value.target.value);

      setFilterData([...result]);
    } else {
      setFilterData(data);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <h1 className='title'>Resource List </h1>
        <Row justify='end'>
          <Col lg={8} md={12} xs={24} style={{ marginBottom: '1em' }}>
            {/* <Search placeholder="Search with name, ohone number or email.." onSearch={(e) => handleSearch(e)}  /> */}
            <Input.Search enterButton onSearch={handleSearch} onChange={handleChange} placeholder='Search with name or type...' />
          </Col>
          <Col xs={24}>
            <Table columns={columns} dataSource={filterData} scroll={{ x: 1000 }} size='middle' />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ResourceList;
