import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin, Image, Typography, Button, Input, Modal, Form } from 'antd';
import { FaUser } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md';
import { AdminAPI } from '../../utils/Api';
import ErrorHandler from '../../utils/ErrorHandler';
import ProfilePic from '../../assests/images/profilePic.png';
import './Profile.less';
import { AuthAPI } from '../../utils/Api';
import { passwordValidation } from '../../utils/Validations';
import Notification from '../../components/controls/Notification';
import useLogout from './../../utils/logout';

const { Paragraph, Title } = Typography;

const Profile = () => {
  const [passResetForm] = Form.useForm();
  const history = useHistory();
  const logout = useLogout();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPassModalVisable, setIsPassModalVisable] = useState(false);

  useEffect(() => {
    let loadData = true;
    setLoading(true);
    AdminAPI.get(`/user/user-profile-info`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    }).then((response) => {
      if (loadData) {
        setUser(response.data.data);
        setLoading(false);
      }
    });

    return () => (loadData = false);
  }, []);

  const showPassModal = () => {
    setIsPassModalVisable(true);
  };

  const handleResetPassCancel = () => {
    setIsPassModalVisable(false);
  };

  const onPasswordResetFinish = async (values) => {
    try {
      setLoading(true);
      await AuthAPI.post('/sales/reset-password', { new_password: values.password, id: user.id });
      Notification('Done!', 'Password Changed Successfully', 'success');
      setLoading(false);
      setIsPassModalVisable(false);
      localStorage.clear();
      history.push('/login');
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Error', 'error');
        passResetForm.setFieldsValue({
          password: '',
          confirm: '',
        });
        setLoading(false);
      } else {
        Notification('Something went wrong', 'Please check your connection', 'error');
        passResetForm.setFieldsValue({
          password: '',
          confirm: '',
        });
        setLoading(false);
      }
    }
  };

  const resetPassTitle = (
    <Paragraph className='assigned-shop__title'>
      <div className='item'>
        <FaUser className='icon-color' /> Username: <span className='value'>{user.name}</span>
      </div>
      <div className='item'>
        <MdAlternateEmail className='icon-color' /> Email: <span className='value'>{user.email}</span>
      </div>
    </Paragraph>
  );

  return (
    <Spin spinning={loading}>
      <div className='profile-container'>
        <Modal
          title={resetPassTitle}
          visible={isPassModalVisable}
          onCancel={handleResetPassCancel}
          footer={[
            <Button key='back' onClick={handleResetPassCancel} danger type='text'>
              Cancel
            </Button>,
          ]}>
          <Spin spinning={loading}>
            <Form
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onPasswordResetFinish}
              autoComplete='off'
              form={passResetForm}>
              <Form.Item
                label='New Password'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your new password!',
                  },
                  {
                    pattern: passwordValidation,
                    message: 'Password should be at least one character, one symbol, one uppercase and one lowercase and minimum 8 character long)',
                  },
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item
                label='Confirm Password'
                name='confirm'
                rules={[
                  {
                    required: true,
                    message: 'Please input your new password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Passwords do not matched!'));
                    },
                  }),
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button type='primary' htmlType='submit'>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <div>
          <Image
            preview={{
              visible: false,
            }}
            width={200}
            src={user?.photo != '' ? process.env.REACT_APP_s3_cdn + user?.photo : ProfilePic}
            alt='user photo'
          />
        </div>
        <div className='content'>
          <Title level={2} className='name'>
            {user.name}
            <Title level={5} className='role'>
              ({user.role_name})
            </Title>
          </Title>
          <Title level={5} className='email'>
            {user.email}
          </Title>
          <Title level={5} className='email'>
            {user.contact_no}
          </Title>
        </div>
        <div className='actions'>
          <Button type='primary' onClick={() => showPassModal()}>
            Reset Password
          </Button>
          <Button
            type='text'
            danger
            onClick={() => {
              setLoading(true);
              logout()
                .then(() => {
                  setLoading(false);
                  Notification('Successfully logged.', '', 'success');
                })
                .catch(() => {
                  Notification('Something went wrong.', '', 'error');
                });
            }}>
            Logout
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default Profile;
