import React, { createContext, useState } from "react";

const AppRootContext = createContext();

const AppRootContextProvider = ({ children }) => {
  const [navIsVisible, setNavIsVisible] = useState(false);
  const [locationList, setLocationList] = useState([]);

  return <AppRootContext.Provider value={{ navIsVisible, setNavIsVisible, locationList, setLocationList }}>{children}</AppRootContext.Provider>;
};

export { AppRootContext, AppRootContextProvider };
