import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Switch, Spin, Tooltip, Modal, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ErrorHandler from '../../utils/ErrorHandler';

import { AdminAPI } from '../../utils/Api';
import Notification from '../../components/controls/Notification';
import { hasAccess } from '../../utils/Helper';

const { confirm } = Modal;

const DashboardList = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await AdminAPI.get('/dashboard/admin/list', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });

        const formatData = data.map((el, i) => {
          return {
            key: i + 1,
            id: el.id,
            report_name: el.name,
            report_description: el.description,
            query: el.link,
            rolesname: el.rolesname.join(', '),
            status: el.status,
          };
        });
        setData(formatData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  const updateStatus = (record, event) => {
    let status = event | false;
    let val = data;
    val[val.findIndex((obj) => obj.id === record.id)].status = status;
    setData([...val]);

    const value = {
      status: event,
    };
    setLoadingSwitch(true);
    AdminAPI.patch(`/dashboard/status-update/${record.id}`, value, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
      .then((response) => {
        setLoadingSwitch(false);
        Notification('Successful', 'Query status has been modified', 'success');
      })
      .catch((err) => {
        Notification('Error!', 'Something went wrong!!', 'error');
        setLoadingSwitch(false);
      });
  };

  const removeQuery = (record, event) => {
    confirm({
      title: 'Do you want to delete these items?',
      icon: <ExclamationCircleOutlined />,
      content: 'When clicked the OK button, it cannot be reverted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let temp = data.filter((i) => i.key !== record.id);
        setData([...temp]);

        AdminAPI.delete(`/dashboard/delete/${record.id}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
          .then((response) => {
            setLoadingSwitch(false);
            Notification('Successful', 'Query remove successfully', 'success');
          })
          .catch((err) => {
            Notification('Error!', 'Something went wrong!!', 'error');
            setLoadingSwitch(false);
          });
        setData(data.filter((el) => el.id !== record.id));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'report_name',
      key: 'report_name',
    },
    {
      title: 'Description',
      dataIndex: 'report_description',
      key: 'report_description',
    },
    {
      title: 'Roles',
      dataIndex: 'rolesname',
      key: 'rolesname',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (text, record) => (
        <Switch
          size='small'
          loading={loadingSwitch}
          defaultChecked={record.status}
          disabled={hasAccess(43) ? false : true}
          onChange={(e) => {
            updateStatus(record, e);
          }}
          key={record.id}></Switch>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          {hasAccess(43) && (
            <Tooltip title='dashboard Modify'>
              <EditOutlined
                className=' table-icon assign '
                onClick={() => {
                  history.push('/dashboard-manager/update-dashboard/' + record.id);
                }}
              />
            </Tooltip>
          )}
          {hasAccess(44) && (
            <Tooltip title='dashboard Remove'>
              <DeleteOutlined
                className=' table-icon delete '
                onClick={(e) => {
                  removeQuery(record, e);
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* <h1 style={{ fontWeight: '600', color: '#545454' }}> Dashboard List </h1> */}
      <Spin spinning={loading} size='large'>
        <h1 className='title'>Dashboard List</h1>
        <Row>
          <Col lg={{ span: 24, offset: 0 }}>
            <Table dataSource={data} columns={columns} scroll={{ x: 950 }} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default DashboardList;
