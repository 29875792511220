import React, { useState, useEffect } from 'react';
import { Table, Space, Row, Col, Input, Tooltip, Form, Select, Empty, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import FuzzySearch from 'fuzzy-search';

import ErrorHandler from '../../utils/ErrorHandler';
import { AdminAPI } from '../../utils/Api';
import { hasAccess } from '../../utils/Helper';
import Notification from '../../components/controls/Notification';

const { Option } = Select;

const StockLists = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [filteredStockData, setFilteredStockData] = useState([]);

  useEffect(() => {
    fetchBranch();
  }, []);

  const fetchBranch = () => {
    AdminAPI.get('/stock/get-branch-list', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        setBranchList([...res.data.data]);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
          Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      });
  };

  const handleOnSelect = (value) => {
    setLoading(true);
    setStockData([]);
    setFilteredStockData([]);
    AdminAPI.get(`/stock/get-stock-list/${value}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
      .then((res) => {
        const info = res.data.data.map((el, i) => ({ ...el, key: i }));
        setStockData([...info]);
        setFilteredStockData([...info]);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      })
      .finally(() => setLoading(false));
  };

  const searcher = new FuzzySearch(filteredStockData, ['user_name', 'products', 'branch_name'], {
    sort: true,
  });

  const handleSearch = (value) => {
    if (value) {
      const result = searcher.search(value);
      setFilteredStockData([...result]);
    } else {
      setFilteredStockData(stockData);
    }
  };

  const handleChange = (value) => {
    if (value.target.value) {
      const result = searcher.search(value.target.value);

      setFilteredStockData([...result]);
    } else {
      setFilteredStockData(stockData);
    }
  };

  const columns = [
    {
      title: 'Product',
      dataIndex: 'products',
      key: 'products',
      sorter: (a, b) => a.products.localeCompare(b.products),

      showSorterTooltip: false,
    },

    {
      title: 'Branch',
      dataIndex: 'branch_name',
      key: 'branch_name',
    },
    {
      title: 'Added By',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Stock Date',
      dataIndex: 'stock_date',
      key: 'stock_date',
      sorter: (a, b) => new Date(a.stock_date) - new Date(b.stock_date),
      showSorterTooltip: false,
    },
  ];

  return (
    <Spin spinning={loading}>
      <h1 className='title'>Stock List</h1>
      <Form
        name='allstock'
        // onFinish={onFinish}
        form={form}>
        <Row gutter={[10, 10]}>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item name='branch' label='Select a Branch' labelCol={{ span: 24 }} rules={[{ required: true, message: 'Please enter Branch' }]}>
              <Select
                placeholder='Please select a branch'
                style={{ width: '100%' }}
                onSelect={(val) => handleOnSelect(val)}
                allowClear
                showArrow
                maxTagCount={2}
                showSearch
                optionFilterProp='children'>
                {branchList.map((v, i) => (
                  <Option value={v.id} key={i}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {stockData.length > 0 ? (
        <>
          <Row justify='end' style={{ marginBottom: '20px' }}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <Input.Search placeholder='Search by Product, Name, Brand...' enterButton allowClear onSearch={handleSearch} onChange={handleChange} />
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={filteredStockData}
            // onChange={handleTableChange}
            scroll={{ x: 1000 }}
          />
        </>
      ) : (
        <Empty />
      )}
    </Spin>
  );
};

export default StockLists;
