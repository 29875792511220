import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/controls/Notification';
import { AdminAPI, SalesAPI } from '../../utils/Api';
import ErrorHandler from '../../utils/ErrorHandler';
import { Form, Input, Button, Select, Typography, Row, Col, Divider, Spin, Upload, Table, Switch } from 'antd';
import { MailOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { mobileNumberValidation } from './../../utils/Validations';
import './../../styles/PageStyles/CreateUser.less';
import { useParams } from 'react-router-dom';
import ProfilePic from '../../assests/images/profilePic.png';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const EditUser = () => {
  const [createUserForm] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [roleType, setroleType] = useState();
  const [shopList, setShopList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [allUsersEmail, setAllUsersEmail] = useState([]);
  const [allUsersContact, setAllUsersContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [branchDisable, setBranchDisable] = useState(true);
  const [branchData, setBranchData] = useState([]);
  const branchCol = [
    {
      title: 'Shop Name',
      dataIndex: 'shop_name',
    },
    {
      title: 'Branch Name',
      dataIndex: 'branch_name',
    },
    {
      title: 'Status',
      dataIndex: 'stts',
      key: 'action',
      render: (text, record) => (
        <Switch
          size='small'
          onChange={(e) => {
            let val = branchData;
            val[val.findIndex((obj) => obj.branch_id == record.branch_id)].delete_marker = !e;
            if (e) {
              let a = val.map((b) => {
                return {
                  ...b,
                  delete_marker: b.branch_id === record.branch_id ? b.delete_marker : true,
                };
              });
              let activeBranch = a.filter((v) => v.delete_marker == false);
              fetchBranch(activeBranch[0].shop_id, roleType);
              if (activeBranch.length === 1) {
                createUserForm.setFieldsValue({
                  shop_id: activeBranch[0].shop_id,
                  branch_id: activeBranch[0].branch_id,
                });
              }
              setBranchData([...a]);
            } else {
              setBranchData([...val]);
              let activeBranch = val.filter((v) => v.delete_marker == false);
              if (activeBranch.length === 1) {
                createUserForm.setFieldsValue({
                  shop_id: activeBranch[0].shop_id,
                  branch_id: activeBranch[0].branch_id,
                });
              }
            }
          }}
          key={record.branch_id}
          checked={!branchData[branchData.findIndex((obj) => obj.branch_id === record.branch_id)].delete_marker}
          defaultChecked={!record.delete_marker}></Switch>
      ),
    },
  ];

  const { id } = useParams();

  const history = useHistory();

  const fetchRole = async () => {
    try {
      const { data } = await AdminAPI.get('/user/role-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setRoleList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const fetchShop = async () => {
    try {
      const { data } = await AdminAPI.get('/shop/shop-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setShopList(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const fetchBranch = async (shop_id, type) => {
    setLoading(true);
    try {
      const { data } = await AdminAPI.get(`/shop/branch-list/${shop_id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      setBranchList(data.data);
      let ids = data.data.map((v) => v.id);
      if (type == 66) {
        createUserForm.setFieldsValue({
          branch_id: ids,
          shop_id: shop_id,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const fetchShopWithBranchID = (branch_id) => {
    let obj = {};
    let arr = branchList.filter((v) => {
      if (v.id == branch_id) {
        let shop = shopList.filter((s) => s.id === v.shop_id);
        obj = shop[0];
      }
    });

    return obj;
  };

  const onBranchChange = (val) => {
    if (val) {
      let a = branchData.map((b) => {
        return {
          ...b,
          delete_marker: b.branch_id === val ? false : true,
        };
      });
      setBranchData([...a]);
      var exist = branchData.filter((v) => v.branch_id == val);
      if (exist.length > 0) {
        Notification('Exist', 'Already added in the table', 'warning');
      } else {
        var temp = branchList.filter((v) => v.id == val);
        let shop = fetchShopWithBranchID(val);
        let branch = branchData.map((v) => {
          return {
            ...v,
            delete_marker: true,
          };
        });
        let obj = {
          branch_name: temp[0].branch_name,
          shop_name: shop.shop_name,
          branch_id: temp[0].id,
          shop_id: shop.id,
          delete_marker: false,
        };

        setBranchData([...branch, obj]);
      }
    } else {
    }
  };

  const onShopChange = (val) => {
    setBranchList([]);
    createUserForm.setFieldsValue({
      branch_id: null,
    });
    if (val) {
      fetchBranch(val, roleType);
    } else {
      setBranchList([]);
      createUserForm.setFieldsValue({
        branch_id: [],
      });
    }
  };

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const { data } = await AdminAPI.get(`/user/user-info/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      let userInfo = data.data.userInfo;

      createUserForm.setFieldsValue({
        name: userInfo.name,
        contact_no: '0' + userInfo.contact_no,
        email: userInfo.email,
        address: userInfo.address,
        role_id: userInfo.user_role_map.role_id,
      });

      setroleType(data.data.type);
      if (data.data.type == 2) {
        setBranchDisable(false);
        let activeBranch = data.data.user_map.filter(v => !v.delete_marker)
        createUserForm.setFieldsValue({
          shop_id: activeBranch[0].branchid.shop.id,
          branch_id: activeBranch[0].branch_id,
        });
        fetchBranch(activeBranch[0].branchid.shop.id, data.data.type);
        let arr = data.data.user_map.map((v) => {
          return {
            branch_name: v.branchid.branch_name,
            shop_name: v.branchid.shop.shop_name,
            branch_id: v.branchid.id,
            shop_id: v.branchid.shop.id,
            delete_marker: v.delete_marker,
            ubm_id: v.id,
          };
        });

        setBranchData(arr);
      } else if (data.data.type == 66) {
        fetchBranch(data.data.user_map.shop_id, data.data.type);
      }

      setImageUrl(userInfo.photo);

      if (userInfo) {
        fetchAllUsers(userInfo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  const fetchAllUsers = async (info) => {
    try {
      const { data } = await AdminAPI.get('/user/user-list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      setAllUsersEmail(data?.data?.filter((val) => val.email !== info?.email).map((el) => el.email));
      setAllUsersContact(data?.data?.filter((val) => val.contact_no !== info?.contact_no).map((el) => '0' + el.contact_no));
    } catch (error) {
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  useEffect(() => {
    fetchRole();
    fetchShop();
    fetchUserInfo();
  }, []);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Notification('Error', 'You can only upload JPG/PNG file.', 'error');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Notification('Error', 'Image must smaller than 5MB.', 'error');
    }

    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onRoleChange = (val) => {
    if (val) {
      let role = roleList.filter((v) => v.id == val);
      setroleType(role[0].type);
      createUserForm.setFieldsValue({
        shop_id: null,
        branch_id: [],
      });
      if (role[0].type == 66) {
        setBranchDisable(true);
      } else {
        setBranchDisable(false);
      }
    } else {
      setBranchDisable(false);
    }
  };

  const onFinish = async (values) => {
    values.photo = imageUrl;
    values.user_id = id;
    values.type = roleType;
    setLoading(true);
    try {
      const { data } = await AdminAPI.post('/user/user-update', values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      setLoading(false);
      if (data.message == 'user exist') {
        Notification('User already Exist with this phone number or email', '', 'warning');
      } else {
        Notification('User updated successfully', '', 'success');
        history.push('/user-manager/view-user');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        ErrorHandler(error?.response?.data?.message, history);
        Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
      } else {
        Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '20px' }}>
        <h1 className='title'>Update User </h1>
        <Form
          name='create-user'
          initialValues={{
            remember: true,
          }}
          form={createUserForm}
          layout='vertical'
          onFinish={onFinish}>
          <Divider orientation='left'>Basic Information</Divider>
          <br />
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label='Phone Number'
                name='contact_no'
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number',
                  },
                  {
                    pattern: mobileNumberValidation,
                    message: 'Phone number is invalid',
                  },
                  {
                    validator: (_, value) => (!allUsersContact?.includes(value) ? Promise.resolve() : Promise.reject('Phone  number is already taken by another user')),
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email address',
                  },
                  {
                    type: 'email',
                    message: 'Invalid email address',
                  },
                  {
                    validator: (_, value) => (!allUsersEmail?.includes(value) ? Promise.resolve() : Promise.reject('Email is already taken by another user')),
                  },
                ]}>
                <Input prefix={<MailOutlined className='site-form-item-icon' />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label='Address'
                name='address'
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your address",
                //     },
                // ]}
              >
                <TextArea allowClear={true} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4} md={4} xl={4}>
              <div className='flex-container'>
                <p>Add Photo</p>
                <Upload
                  name='Users'
                  listType='picture-card'
                  className='avatar-uploader'
                  showUploadList={false}
                  action={(file) => {
                    let data = new FormData();
                    data.append('Users', file);
                    setLoading(true);

                    SalesAPI.post('/upload/files', data, { headers: { 'Content-Type': `multipart/form-data;` } })
                      .then((response) => {
                        setImageUrl(`${response.data.file_name}`);
                        setLoading(false);
                      })
                      .catch((err) => {
                        Notification('Error', 'Could not upload photo', 'error');
                        setLoading(false);
                      });
                  }}
                  beforeUpload={beforeUpload}>
                  {imageUrl ? <img src={process.env.REACT_APP_s3_cdn + imageUrl} alt='avatar' style={{ maxWidth: '75%' }} /> : <img src={ProfilePic} alt='avatar' style={{ maxWidth: '75%' }} />}
                </Upload>
              </div>
            </Col>
            <Col xs={24} sm={4} md={4} xl={4} style={{ display: 'flex', alignItems: 'center' }}>
              <div className='verticalAlign'>
                1. The image must be clear and recent <br />
                2. Photo must be passport size (4x4)
                <br />
                3. Size must not exceed 5mb. <br />
              </div>
            </Col>
          </Row>
          <Divider orientation='left'>Assignment</Divider>
          <br />
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label='Role'
                name='role_id'
                rules={[
                  {
                    required: true,
                    message: 'Please select a role',
                  },
                ]}>
                <Select
                  allowClear
                  showArrow
                  showSearch
                  placeholder='select role'
                  optionFilterProp='children'
                  onChange={onRoleChange}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                  {roleList.map((role) => {
                    return (
                      <Option value={role.id} key={role.id}>
                        {role.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {roleType == 2 || roleType == 66 ? (
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item
                  label='Shop'
                  name='shop_id'
                  rules={[
                    {
                      required: true,
                      message: 'Please select a shop',
                    },
                  ]}>
                  <Select
                    onChange={onShopChange}
                    allowClear
                    showArrow
                    showSearch
                    placeholder='select shop'
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                    {shopList.map((val) => {
                      return (
                        <Option value={val.id} key={val.id}>
                          {val.shop_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label='Branch'
                  name='branch_id'
                  rules={[
                    {
                      required: true,
                      message: 'Please select a branch',
                    },
                  ]}>
                  <Select
                    allowClear
                    showArrow
                    onChange={onBranchChange}
                    showSearch
                    placeholder='select branch'
                    disabled={branchDisable}
                    mode={branchDisable ? 'multiple' : 'single'}
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                    {branchList.map((val) => {
                      return (
                        <Option value={val.id} key={val.id}>
                          {val.branch_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {roleType == 2 ? (
            <Row>
              <Col xs={12}>
                <Table dataSource={branchData} columns={branchCol} size='small' />
              </Col>
            </Row>
          ) : null}

          <br />
          <br />

          <Row justify='center'>
            <Col>
              <Button block size='large' type='primary' htmlType='submit'>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default EditUser;
