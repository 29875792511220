import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import {
  PieChartOutlined,
  PlusCircleOutlined,
  LineChartOutlined,
  ShopOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  AppstoreOutlined,
  UsergroupDeleteOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  FileSearchOutlined,
  StockOutlined,
  SnippetsOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import { FaUserTie } from "react-icons/fa";
import {
  BsFillFileEarmarkFontFill,
  BsFillFileTextFill,
  BsFillFileEarmarkMedicalFill,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { hasAccess } from "../utils/Helper";

import { toTitleCase } from "../utils/Helper";

const { SubMenu } = Menu;

function TopicMenu({ onClick }) {
  const location = useLocation();

  const [path, setPath] = useState("");

  useEffect(() => {
    // get location name
    const locationName = location.pathname.split("/");
    // extract main route that is after manager name
    const secondSplittedString = locationName[2];
    // if found then set path name or set manager as dashboard
    setPath(secondSplittedString || "manager");

    const replacedString = secondSplittedString?.replace(/-/g, " ");
    document.title = `Sentinel | ${toTitleCase(replacedString || "manager")} `;
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div>
      <Menu mode="inline" selectedKeys={[path]} onClick={() => onClick()}>
        <Menu.Item key="manager" icon={<AppstoreOutlined />}>
          Home <Link to="/manager" />
        </Menu.Item>
        {hasAccess(60) && (
          <Menu.Item key="toggle" icon={<CodeSandboxOutlined />}>
            Order Panel Status <Link to="/stock-status/toggle" />
          </Menu.Item>
        )}
        {hasAccess(17) && (
          <SubMenu key="role" icon={<FaUserTie />} title="Role Manager">
            {hasAccess(22) && (
              <Menu.Item key="create-role" icon={<PlusOutlined />}>
                Create Role <Link to="/role-manager/create-role" />
              </Menu.Item>
            )}
            {hasAccess(23) && (
              <Menu.Item key="role-list" icon={<UnorderedListOutlined />}>
                Role List
                <Link to="/role-manager/role-list" />
              </Menu.Item>
            )}
            {hasAccess(24) && (
              <Menu.Item key="add-resource" icon={<PlusOutlined />}>
                Add Resource <Link to="/role-manager/add-resource" />
              </Menu.Item>
            )}
            {hasAccess(25) && (
              <Menu.Item key="resource-list" icon={<UnorderedListOutlined />}>
                Resource List <Link to="/role-manager/resource-list" />
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {hasAccess(18) && (
          <SubMenu
            key="users"
            icon={<UsergroupDeleteOutlined />}
            title="User Manager"
          >
            {hasAccess(26) && (
              <Menu.Item key="create-user" icon={<PlusOutlined />}>
                Create User <Link to="/user-manager/create-user" />
              </Menu.Item>
            )}
            {hasAccess(27) && (
              <Menu.Item key="view-user" icon={<UnorderedListOutlined />}>
                View User <Link to="/user-manager/view-user" />
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {hasAccess(19) && (
          <SubMenu key="shops" icon={<ShopOutlined />} title="Shop Manager">
            {hasAccess(28) && (
              <Menu.Item key="create-shop" icon={<PlusOutlined />}>
                Create Shop <Link to="/shop-manager/create-shop" />
              </Menu.Item>
            )}
            {hasAccess(29) && (
              <Menu.Item key="view-shop" icon={<UnorderedListOutlined />}>
                View Shop <Link to="/shop-manager/view-shop" />
              </Menu.Item>
            )}
            {hasAccess(30) && (
              <Menu.Item key="create-branch" icon={<PlusOutlined />}>
                Create Branch <Link to="/shop-manager/create-branch" />
              </Menu.Item>
            )}
            {hasAccess(31) && (
              <Menu.Item key="view-branch" icon={<UnorderedListOutlined />}>
                View Branch <Link to="/shop-manager/view-branch" />
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {hasAccess(20) && (
          <SubMenu
            key="reports"
            icon={<FileDoneOutlined />}
            title="Report Manager"
          >
            {(hasAccess(47) || hasAccess(48)) && (
              <SubMenu
                key="inventory"
                icon={<FileDoneOutlined />}
                title="Inventory"
              >
                {hasAccess(47) && (
                  <Menu.Item
                    key="by-trade-lifting-report"
                    icon={<BsFillFileEarmarkFontFill />}
                  >
                    By Trader Lifting Report
                    <Link to="/report-manager/by-trade-lifting-report" />
                  </Menu.Item>
                )}

                {hasAccess(48) && (
                  <Menu.Item
                    key="on-hand-stock-report"
                    icon={<BsFillFileTextFill />}
                  >
                    On Hand Stock Report{" "}
                    <Link to="/report-manager/on-hand-stock-report" />
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            {hasAccess(45) && (
              <Menu.Item key="by-channel-order" icon={<FileAddOutlined />}>
                By Channel Sales Report{" "}
                <Link to="/report-manager/by-channel-order" />
              </Menu.Item>
            )}

            {hasAccess(46) && (
              <Menu.Item key="by-trader-sales" icon={<FileSearchOutlined />}>
                By Trader Sales Report{" "}
                <Link to="/report-manager/by-trader-sales" />
              </Menu.Item>
            )}
            {hasAccess(49) && (
              <Menu.Item
                key="one-view-report"
                icon={<BsFillFileEarmarkMedicalFill />}
              >
                One View Report VUSE
                <Link to="/report-manager/one-view-report" />
              </Menu.Item>
            )}
            {hasAccess(50) && (
              <Menu.Item
                key="by-consumer-sales"
                icon={<BsFillFileEarmarkMedicalFill />}
              >
                By Consumer Report
                <Link to="/report-manager/by-consumer-sales" />
              </Menu.Item>
            )}
            {hasAccess(51) && (
              <Menu.Item
                key="registered-users"
                icon={<BsFillFileEarmarkMedicalFill />}
              >
                Registered Users
                <Link to="/report-manager/registered-users" />
              </Menu.Item>
            )}
            {hasAccess(59) && (
              <Menu.Item
                key="sob-report"
                icon={<BsFillFileEarmarkMedicalFill />}
              >
                SOB Report
                <Link to="/report-manager/sob-report" />
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {hasAccess(21) && (
          <SubMenu
            key="dashboard"
            icon={<LineChartOutlined />}
            title="Dashboard Manager"
          >
            {hasAccess(34) && (
              <Menu.Item key="dashboards" icon={<PieChartOutlined />}>
                Dashboards <Link to="/dashboard-manager/dashboards" />
              </Menu.Item>
            )}
            {hasAccess(32) && (
              <Menu.Item key="create-dashboard" icon={<PlusCircleOutlined />}>
                Create Dashboard{" "}
                <Link to="/dashboard-manager/create-dashboard" />
              </Menu.Item>
            )}
            {hasAccess(33) && (
              <Menu.Item key="dashboard-list" icon={<UnorderedListOutlined />}>
                Dashboard List <Link to="/dashboard-manager/dashboard-list" />
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {hasAccess(53) && (
          <SubMenu
            key="stockmanager"
            icon={<StockOutlined />}
            title="Stock Manager"
          >
            {hasAccess(54) && (
              <Menu.Item key="allocate-stock" icon={<PieChartOutlined />}>
                Allocate Event Stock <Link to="/stock-manager/allocate-stock" />
              </Menu.Item>
            )}
            {hasAccess(55) && (
              <Menu.Item key="stock-lists" icon={<PlusCircleOutlined />}>
                Event Stock List
                <Link to="/stock-manager/stock-lists" />
              </Menu.Item>
            )}
            {hasAccess(57) && (
              <Menu.Item key="normal-branch-stock" icon={<SnippetsOutlined />}>
                Normal Branch Stock
                <Link to="/stock-manager/normal-branch-stock" />
              </Menu.Item>
            )}
          </SubMenu>
        )}
      </Menu>
    </div>
  );
}

export default TopicMenu;
