import React, { useEffect, useState } from 'react';
import { Button, Card, Spin, Empty } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { AdminAPI } from '../../utils/Api';
import ErrorHandler from '../../utils/ErrorHandler';
import Notification from '../../components/controls/Notification';
import './DashboardStyle.less';

const { Meta } = Card;

const Dashboards = () => {
  const history = useHistory();
  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { data } = await AdminAPI.get('/dashboard/list', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });
        setDashboards([...data]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  return (
    <Spin spinning={loading} size='large'>
      {dashboards.length > 0 ? (
        <div className='dashboardhome-div'>
          {/* <h1 style={{ fontWeight: '600', color: '#545454', marginLeft: '40px' }}> Dashboard List </h1> */}
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'normal' }}>
            {dashboards.map((v, index) => {
              return (
                <Card
                  hoverable
                  className='card-div'
                  key={index}
                  title={
                    <span style={{ display: 'grid', color: 'white', fontWeight: '600' }}>
                      {' '}
                      {v.name} <span style={{ fontSize: '15px', color: 'white', fontWeight: '200' }}> Dashboard Name </span>
                    </span>
                  }>
                  <div style={{ position: 'relative', marginBottom: '10px' }}>
                    <div style={{ position: 'absolute', backgroundColor: '#c4e1ff ', height: '100%', width: '100%', opacity: '.2' }} />
                    <iframe src={v.link} style={{ width: '100%' }} />
                  </div>
                  <Meta style={{ fontWeight: '200', fontSize: '18px', marginBottom: '5px' }} description={<div style={{ color: 'white', fontSize: '15px' }}> Description </div>} />
                  <Meta style={{ fontWeight: '600', fontSize: '15px' }} className='description-div' description={<div style={{ color: 'white' }}> {v.description} </div>} />
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                    <Link to={{ path: v.link, pathname: `/dashboard-manager/dashboard-card/${v.id}` }}>
                      <Button className='view-btn'> View </Button>
                    </Link>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </Spin>
  );
};

export default Dashboards;
