import React, { useEffect, useState } from 'react';
import { Drawer, Layout, Avatar, Menu, Button, Space, Dropdown, Typography } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons';

import { AdminAPI } from '../utils/Api';
import MenuTopics from '../container/MenuTopics';
import AppRoutes from '../routes';
import Logo from '../assests/images/logo.png';
import ProfilePic from '../assests/images/profilePic.png';
import Notification from '../components/controls/Notification';
import ErrorHandler from '../utils/ErrorHandler';
import useLogout from '../utils/logout';

import './AdminLayout.less';
import '../styles/Navbar.less';
import '../styles/Sidebar.less';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const AdminLayout = () => {
  const history = useHistory();
  const logout = useLogout();

  const [navIsVisible, setNavIsVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const closeSidenav = () => {
    setNavIsVisible(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await AdminAPI.get(`/user/user-profile-info`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });
        setUserInfo(data.data);
      } catch (error) {
        if (error?.response?.data?.message) {
          ErrorHandler(error?.response?.data?.message, history);
          Notification(error?.response?.data?.message, 'Please fix this error and try again. Otherwise communicate with the admin', 'error');
        } else {
          Notification('Something went wrong', 'Please check your internet connection and try again or communicate with the admin', 'error');
        }
      }
    })();
  }, []);

  const openSidenav = () => {
    setNavIsVisible(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1' style={{ padding: '.7em 1em ' }}>
        <Text strong>Profile</Text> <Link to='/me' />
      </Menu.Item>
      <Menu.Item
        key='2'
        style={{ padding: '.7em 1em' }}
        onClick={() => {
          logout()
            .then(() => {
              Notification('Successfully logged out', '', 'success');
            })
            .catch(() => {
              Notification('Something went wrong.', '', 'error');
            });
        }}>
        <Text strong style={{ color: 'red' }}>
          Logout
        </Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Drawer title='NC Admin' placement='left' onClose={closeSidenav} visible={navIsVisible}>
        <MenuTopics onClick={closeSidenav} />
      </Drawer>

      <Sider breakpoint='lg' collapsedWidth='0' trigger={null} className='sidebar-layout'>
        <div style={{ minWidth: '92px' }}>
          <Link to='/manager'>
            <div className='logo'>
              <img src={Logo} height='80px' width='80px' alt='logo' />
              <h1 className='prism-crm-div'>Sentinel Admin</h1>
            </div>
          </Link>
        </div>

        <MenuTopics onClick={() => {}} />
      </Sider>

      <Layout>
        <Header className='site-layout-sub-header-background '>
          <Button className='menu' type='primary' icon={<MenuOutlined />} onClick={openSidenav} />

          <div className='dropdown'>
            <Dropdown className='logout-btn' overlay={menu} placement='topRight' arrow trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space size='middle'>
                  <Avatar src={userInfo?.photo != ""? process.env.REACT_APP_s3_cdn + userInfo?.photo: ProfilePic} style={{ width: '40px', height: '40px' }} alt='user' />
                  <Text strong style={{ color: '#1890ff' }}>
                    {userInfo?.name} ({userInfo?.role_name})
                  </Text>
                  <CaretDownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className='site-layout-background' style={{ padding: '12px 24px', minHeight: 800 }}>
            <AppRoutes />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          NC Admin ©{new Date().getFullYear()} V2 Technologies({process.env.REACT_APP_STAGE})
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
