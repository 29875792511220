export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const hasAccess = (accessNumber) => {
  return localStorage?.getItem('resource_list')?.split(',').includes(accessNumber.toString());
};

hasAccess(3);
